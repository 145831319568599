const endPoints = {
    accessTokens: '/oauth2/accessTokens',
    checkAccessToken: '/oauth2/check-access-token',
    logOut: '/oauth2/revokeToken',
    getTopupProducts: '/api/mcpos/getTopupProducts',
    activateNewSim: '/api/mcpos/activateNewSim',
    getUserInfo: '/api/mcpos/getUserinfo',
    purchaseOrder: '/api/mcpos/createPortingOrder',
    registerStartpackage: '/api/mcpos/registerStartpackage',
    topupOrder: '/api/mcpos/topupOrder',
    getSubscriptionInfo: '/api/cms/phrase/getPhrasesByPageName',
    validatePrepaidNumber: '/api/usertools/validatePrepaidNumber',
};

export default endPoints;
