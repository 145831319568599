export const Countries = [
    {"name": "Norge", "code": "NO"},
    {"name": "Afghanistan", "code": "AF"},
    {"name": "Albania", "code": "AL"},
    {"name": "Algerie", "code": "DZ"},
    {"name": "Amerikansk Samoa", "code": "AS"},
    {"name": "Andorra", "code": "AD"},
    {"name": "Andre Amerikanske Territorier", "code": "UM"},
    {"name": "Angola", "code": "AO"},
    {"name": "Anguilla", "code": "AI"},
    {"name": "Antigua og Barbuda", "code": "AG"},
    {"name": "Arabiske Emirater", "code": "AE"},
    {"name": "Argentina", "code": "AR"},
    {"name": "Armenia", "code": "AM"},
    {"name": "Aruba", "code": "AW"},
    {"name": "Ascension-øyene", "code": "AC"},
    {"name": "Aserbajdsjan", "code": "AZ"},
    {"name": "Australia", "code": "AU"},
    {"name": "Bahamas", "code": "BS"},
    {"name": "Bahrain", "code": "BH"},
    {"name": "Bangladesh", "code": "BD"},
    {"name": "Barbados", "code": "BB"},
    {"name": "Belgia", "code": "BE"},
    {"name": "Belize", "code": "BZ"},
    {"name": "Benin", "code": "BJ"},
    {"name": "Bermuda", "code": "BM"},
    {"name": "Bhutan", "code": "BT"},
    {"name": "Bolivia", "code": "BO"},
    {"name": "Bosnia-Hercegovina", "code": "BA"},
    {"name": "Botswana", "code": "BW"},
    {"name": "Brasil", "code": "BR"},
    {"name": "Britisk territorium i Indiahavet", "code": "IO"},
    {"name": "Brunei", "code": "BN"},
    {"name": "Bulgaria", "code": "BG"},
    {"name": "Burkina Faso", "code": "BF"},
    {"name": "Burma", "code": "BU"},
    {"name": "Burundi", "code": "BI"},
    {"name": "Canada", "code": "CA"},
    {"name": "Caymanøyene", "code": "KY"},
    {"name": "Chile", "code": "CL"},
    {"name": "Christmas Island", "code": "CX"},
    {"name": "Colombia", "code": "CO"},
    {"name": "Cookøyene", "code": "CK"},
    {"name": "Costa Rica", "code": "CR"},
    {"name": "Cuba", "code": "CU"},
    {"name": "Danmark", "code": "DK"},
    {"name": "De nederlandske antiller", "code": "AN"},
    {"name": "Den dominikanske republikk", "code": "DO"},
    {"name": "Den sentralafrikanske rep", "code": "CF"},
    {"name": "Diego Garcia", "code": "XX"},
    {"name": "Djibouti", "code": "DJ"},
    {"name": "Dominica", "code": "DM"},
    {"name": "Ecuador", "code": "EC"},
    {"name": "Egypt", "code": "EG"},
    {"name": "Ekvatorial Guinea", "code": "GQ"},
    {"name": "El Salvador", "code": "SV"},
    {"name": "Elfenbenskysten", "code": "CI"},
    {"name": "Eritrea", "code": "ER"},
    {"name": "Estland", "code": "EE"},
    {"name": "Etiopia", "code": "ET"},
    {"name": "Falkøyene", "code": "FK"},
    {"name": "Fiji", "code": "FJ"},
    {"name": "Filippinene", "code": "PH"},
    {"name": "Finland", "code": "FI"},
    {"name": "Frankrike", "code": "FR"},
    {"name": "Fransk Guyana", "code": "GF"},
    {"name": "Fransk Polynesia", "code": "PF"},
    {"name": "Færøyene", "code": "FO"},
    {"name": "Gabon", "code": "GA"},
    {"name": "Gambia", "code": "GM"},
    {"name": "Georgia", "code": "GE"},
    {"name": "Ghana", "code": "GH"},
    {"name": "Gibraltar", "code": "GI"},
    {"name": "Grenada", "code": "GD"},
    {"name": "Grønland", "code": "GL"},
    {"name": "Guadeloupe", "code": "GP"},
    {"name": "Guam", "code": "GU"},
    {"name": "Guatemala", "code": "GT"},
    {"name": "Guinea", "code": "GN"},
    {"name": "Guinea-Bissau", "code": "GW"},
    {"name": "Guyana", "code": "GY"},
    {"name": "Haiti", "code": "HT"},
    {"name": "Hellas", "code": "GR"},
    {"name": "Honduras", "code": "HN"},
    {"name": "Hong Kong", "code": "HK"},
    {"name": "Hviterussland", "code": "BY"},
    {"name": "India", "code": "IN"},
    {"name": "Indonesia", "code": "ID"},
    {"name": "Irak", "code": "IQ"},
    {"name": "Iran", "code": "IR"},
    {"name": "Irland", "code": "IE"},
    {"name": "Island", "code": "IS"},
    {"name": "Israel", "code": "IL"},
    {"name": "Italia", "code": "IT"},
    {"name": "Jamaica", "code": "JM"},
    {"name": "Japan", "code": "JP"},
    {"name": "Jemen", "code": "YE"},
    {"name": "Jomfruøyene, Britisk", "code": "VG"},
    {"name": "Jomfruøyene, US", "code": "VI"},
    {"name": "Jordan", "code": "JO"},
    {"name": "Kambodsja", "code": "KH"},
    {"name": "Kamerun", "code": "CM"},
    {"name": "Kapp Verde", "code": "CV"},
    {"name": "Kasakhstan", "code": "KZ"},
    {"name": "Kenya", "code": "KE"},
    {"name": "Kina", "code": "CN"},
    {"name": "Kirgisistan", "code": "KG"},
    {"name": "Kiribati", "code": "KI"},
    {"name": "Komorene", "code": "KM"},
    {"name": "Kongo", "code": "CG"},
    {"name": "Kongo, Dem, Rep,", "code": "CD"},
    {"name": "Kosovo", "code": "KO"},
    {"name": "Kroatia", "code": "HR"},
    {"name": "Kuwait", "code": "KW"},
    {"name": "Kypros", "code": "CY"},
    {"name": "Laos", "code": "LA"},
    {"name": "Latvia", "code": "LV"},
    {"name": "Lesotho", "code": "LS"},
    {"name": "Libanon", "code": "LB"},
    {"name": "Liberia", "code": "LR"},
    {"name": "Libya", "code": "LY"},
    {"name": "Liechtenstein", "code": "LI"},
    {"name": "Litauen", "code": "LT"},
    {"name": "Luxembourg", "code": "LU"},
    {"name": "Macao", "code": "MO"},
    {"name": "Madagaskar", "code": "MG"},
    {"name": "Makedonia", "code": "MK"},
    {"name": "Malawi", "code": "MW"},
    {"name": "Malaysia", "code": "MY"},
    {"name": "Maldivene", "code": "MV"},
    {"name": "Mali", "code": "ML"},
    {"name": "Malta", "code": "MT"},
    {"name": "Marokko", "code": "MA"},
    {"name": "Marshalløyene", "code": "MH"},
    {"name": "Martinique", "code": "MQ"},
    {"name": "Mauritania", "code": "MR"},
    {"name": "Mauritius", "code": "MU"},
    {"name": "Mayotte-øyene", "code": "YT"},
    {"name": "Mexico", "code": "MX"},
    {"name": "Mikronesiaføderasjon", "code": "FM"},
    {"name": "Moldova", "code": "MD"},
    {"name": "Monaco", "code": "MC"},
    {"name": "Mongolia", "code": "MN"},
    {"name": "Montenegro", "code": "ME"},
    {"name": "Montserrat", "code": "MS"},
    {"name": "Mosambik", "code": "MZ"},
    {"name": "Myanmar", "code": "MM"},
    {"name": "Namibia", "code": "NA"},
    {"name": "Nauru", "code": "NR"},
    {"name": "Nederland", "code": "NL"},
    {"name": "Nepal", "code": "NP"},
    {"name": "New Zealand", "code": "NZ"},
    {"name": "Nicaragua", "code": "NI"},
    {"name": "Niger", "code": "NE"},
    {"name": "Nigeria", "code": "NG"},
    {"name": "Niue", "code": "NU"},
    {"name": "Nord-Korea", "code": "KP"},
    {"name": "Nord-Marianene", "code": "MP"},
    {"name": "Norfolk-øyene", "code": "NF"},
    {"name": "Ny-Kaledonia", "code": "NC"},
    {"name": "Oman", "code": "OM"},
    {"name": "Pakistan", "code": "PK"},
    {"name": "Palau", "code": "PW"},
    {"name": "Palestina", "code": "PS"},
    {"name": "Panama", "code": "PA"},
    {"name": "Papau Ny-Guinea", "code": "PG"},
    {"name": "Paraguay", "code": "PY"},
    {"name": "Peru", "code": "PE"},
    {"name": "Polen", "code": "PL"},
    {"name": "Portugal", "code": "PT"},
    {"name": "Puerto Rico", "code": "PR"},
    {"name": "Qatar", "code": "QA"},
    {"name": "Reunion", "code": "RE"},
    {"name": "Romania", "code": "RO"},
    {"name": "Russland", "code": "RU"},
    {"name": "Rwanda", "code": "RW"},
    {"name": "Salomonøyene", "code": "SB"},
    {"name": "Samoa", "code": "WS"},
    {"name": "San Marino", "code": "SM"},
    {"name": "Sao Tome og Principe", "code": "ST"},
    {"name": "Saudi-Arabia", "code": "SA"},
    {"name": "Senegal", "code": "SN"},
    {"name": "Serbia", "code": "RS"},
    {"name": "Seychellene", "code": "SC"},
    {"name": "Sierra Leone", "code": "SL"},
    {"name": "Singapore", "code": "SG"},
    {"name": "Slovakia", "code": "SK"},
    {"name": "Slovenia", "code": "SI"},
    {"name": "Somalia", "code": "SO"},
    {"name": "Spania", "code": "ES"},
    {"name": "Sri Lanka", "code": "LK"},
    {"name": "St Helena", "code": "SH"},
    {"name": "St Kitts og Nevis", "code": "KN"},
    {"name": "St Vincent og Grenandinene", "code": "VC"},
    {"name": "St, Lucia", "code": "LC"},
    {"name": "St. Pierre og Miquelon", "code": "PM"},
    {"name": "Storbritannia", "code": "GB"},
    {"name": "Sudan", "code": "SD"},
    {"name": "Surinam", "code": "SR"},
    {"name": "Sveits", "code": "CH"},
    {"name": "Sverige", "code": "SE"},
    {"name": "Swaziland", "code": "SZ"},
    {"name": "Syria", "code": "SY"},
    {"name": "Sør-afrika", "code": "ZA"},
    {"name": "Sør-Korea", "code": "KR"},
    {"name": "Sør-Sudan", "code": "SS"},
    {"name": "Tadsjikistan", "code": "TJ"},
    {"name": "Taiwan", "code": "TW"},
    {"name": "Tanzania", "code": "TZ"},
    {"name": "Thailand", "code": "TH"},
    {"name": "Togo", "code": "TG"},
    {"name": "Tokelau", "code": "TK"},
    {"name": "Tonga", "code": "TO"},
    {"name": "Trinidad Tobago", "code": "TT"},
    {"name": "Tsjad", "code": "TD"},
    {"name": "Tsjekkia", "code": "CZ"},
    {"name": "Tunisia", "code": "TN"},
    {"name": "Turkmenistan", "code": "TM"},
    {"name": "Turks og Caicosøyene", "code": "TC"},
    {"name": "Tuvalu", "code": "TV"},
    {"name": "Tyrkia", "code": "TR"},
    {"name": "Tyskland", "code": "DE"},
    {"name": "Uganda", "code": "UG"},
    {"name": "Ukraina", "code": "UA"},
    {"name": "Ungarn", "code": "HU"},
    {"name": "Uruguay", "code": "UY"},
    {"name": "USA", "code": "US"},
    {"name": "Usbekistan", "code": "UZ"},
    {"name": "Vanuatu", "code": "VU"},
    {"name": "Vatikanstaten", "code": "VA"},
    {"name": "Venezuela", "code": "VE"},
    {"name": "Vietnam", "code": "VN"},
    {"name": "Wallis og Futuna", "code": "WF"},
    {"name": "Zambia", "code": "ZM"},
    {"name": "Zimbabwe", "code": "ZW"},
    {"name": "Østerrike", "code": "AT"},
    {"name": "Øst-Timor", "code": "TL"},
]