import ErrorBoundary from './ErrorHandler.js';
import AppRouter from './AppRouter';

const App = () => {
    return (
        <ErrorBoundary>
            <AppRouter />
        </ErrorBoundary>
    );
}

export default App;
