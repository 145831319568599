import { useContext, Fragment } from 'react';
import MCpos from '../../../../services/MCpos';
import { ClickableShadowCard } from '../../../layout/Elements';
import { Translations as T } from '../../../../resources/translations';
import { MainContext } from "../../../contexts/MainContext";

const Step3ProductCard = ({ cachedSub, selectMultiSub }) => {

    const {
        locale,
    } = useContext(MainContext);

    const euAddons = cachedSub?.productExtras?.[1]?.productAddons;
    const isRequired = cachedSub?.productExtras?.[1]?.isRequired;

    return (
        <Fragment>
            {!isRequired &&
                <div className="col-sm-6 col-md-4 text-center pt-4 mb-2">
                    <div className="cardpointer h-100" onClick={() => selectMultiSub(cachedSub, null, cachedSub.productGroup)}>
                        <ClickableShadowCard>
                            <h3>{'0 ' + T[locale].minutes}</h3>
                            <p>{T[locale].price}: kr 0</p>
                        </ClickableShadowCard>
                    </div>
                </div>
            }
            {euAddons && euAddons.map((euAddon) => {
                return (
                    <div key={euAddon?.productId} className="col col-md-4 text-center pt-4 mb-2">
                        <div className="cardpointer h-100" onClick={() => selectMultiSub(cachedSub, euAddon, cachedSub.productGroup)}>
                            <ClickableShadowCard>
                                <h3>{MCpos.getCounterAmount(euAddon, 'MOROEU') + ' ' + T[locale].minutesTo + ' ' + euAddon?.countryCode}</h3>
                                <p>
                                    {T[locale].price}: kr {euAddon?.userPrice}
                                </p>
                            </ClickableShadowCard>
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default Step3ProductCard;
