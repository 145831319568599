import Header from '../../layout/Header';
import { TopUpProvider } from '../../contexts/TopUpContext';

import MainContent from "./MainContent";

const TopUpPage = () => {

    return (
        <TopUpProvider>
            <div className="page" id="topuppage">
                <Header backLink="menu" />
                <MainContent />
            </div>
        </TopUpProvider>
    );
}

export default TopUpPage;
