export const Languages = {
	no: { name: 'Norwegian', native: 'Norsk', cmsLangId: 2 },
	en: { name: 'English', native: 'Engelsk', cmsLangId: 1 },
	//pl: { name: 'Polish', native: 'Polski', cmsLangId: 3 },
	//ro: { name: 'Romanian', native: 'Română', cmsLangId: 1 },
	//es: { name: 'Spanish', native: 'Español', cmsLangId: 1 },
	//ur: { name: 'Urdu', native: 'اردو',  cmsLangId: 1 },
	//ru: { name: 'Russian', native: 'Pусский', cmsLangId: 1 },
	//fl: { name: 'Filipino', native: 'Pilipino', cmsLangId: 1 },
	//ar: { name: 'Arabic', native: 'العربية', cmsLangId: 1 },
	//fa: { name: 'Persian', native: 'فارسی', cmsLangId: 1 },
	//ti: { name: 'Tigrinya', native: 'ትግርኛ', cmsLangId: 1 },
	//th: { name: 'Thai', native: 'ไทย', cmsLangId: 1 },
};