import { useContext } from 'react';
import MCpos from '../../../../services/MCpos';
import { ClickableShadowCard } from '../../../layout/Elements';
import { Translations as T } from '../../../../resources/translations';
import { TopUpContext } from "../../../contexts/TopUpContext";
import { MainContext } from "../../../contexts/MainContext";

const Step2ProductCard = ({ product, changeStep2 }) => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        topUpCountries,
    } = useContext(TopUpContext);

    const moroAmount = MCpos.getCounterAmount(product?.productExtras?.[0]?.productAddons?.[0], 'MORO');

    return (
        <div className="col-sm-6 col-md-4 text-center pt-4 mb-2">
            <div className="cardpointer h-100" onClick={() => changeStep2(product)}>
                <ClickableShadowCard>
                    <h3>{product.productDescNopp}</h3>
                    <p>
                        {MCpos.getCountryName(topUpCountries, product?.countryCode, locale)}
                        {moroAmount
                            ?
                            (' ' +
                                moroAmount +
                                ' ' +
                                T[locale].minutes)
                            :'-'
                        }
                    </p>
                    <p>
                        {T[locale].price}: kr {product.userPrice}
                    </p>
                </ClickableShadowCard>
            </div>
        </div>
    );
};

export default Step2ProductCard;
