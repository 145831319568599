import { useContext } from 'react';
import Header from '../layout/Header';
import { MenuItem } from '../layout/Elements';
import { Translations } from '../../resources/translations.js';
import { MainContext } from "../contexts/MainContext";

const MainMenu = ({ isPosStand }) => {
    const {
        locale,
    } = useContext(MainContext);

    return (
        <div className="page" id="MainMenu">
            <Header backLink="location" />
            <div className="main-content">
                <div className="container-fluid">
                    <h1 className="title">{Translations[locale].howCanWeHelpYou}</h1>
                    <div className="row justify-content-center">
                        <MenuItem link="prepaidmenu">{Translations[locale].prepaid}</MenuItem>
                        {!isPosStand && <MenuItem link="topup">{Translations[locale].topUp}</MenuItem>}
                        {!isPosStand && <MenuItem link="recenttopups">{Translations[locale].recentTopUps}</MenuItem>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainMenu;
