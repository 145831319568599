import { createContext, useState, useEffect, useContext } from "react";
import { Spinner } from "../layout/Elements";
import { MainContext } from "../contexts/MainContext";

const TopUpContext = createContext(null);

const TopUpProvider = ({ children }) => {

    const {
        setLoggedIn
    } = useContext(MainContext);

    const [topTitle, setTopTitle] = useState({
        top: '',
        sub: '',
    });

    // User specific
    const [msisdn, setMsisdn] = useState('');
    const [lastProduct, setLastProduct] = useState(null);
    const [subscriberName, setSubscriberName] = useState('');

    // Processes / Statuses
    const [showSubs, setShowSubs] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [orderProgress, setOrderProgress] = useState(false);
    const [msisdnConfirmed, setMsisdnConfirmed] = useState(false);
    const [orderConfirmed, setOrderConfirmed] = useState(false);
    const [orderOk, setOrderOk] = useState(false);
    const [orderResponse, setOrderResponse] = useState('');

    // Cart specific
    const [orderTotal, setOrderTotal] = useState(0);
    const [selectedGroups, setSelectedGroups] = useState({
        selectedPACKAGE: null,
        selectedSALDO: null,
        selectedDATA: null,
        selectedTOPUP: null,
    });

    // Product specific
    const [myCash, setMyCash] = useState([]);
    const [myData, setMyData] = useState([]);
    const [subGroups, setSubGroups] = useState([]);
    const [iMinutesData, setIMinutesData] = useState([]);
    const [subGroup, setSubGroup] = useState('');
    const [topUpCountries, setTopUpCountries] = useState([]);
    const [cachedSteps, setCachedSteps] = useState(null);
    const [showMinutes, setShowMinutes] = useState(false);

    return (
        <TopUpContext.Provider
            value={{
                loading,
                setLoading,
                msisdn,
                setMsisdn,
                lastProduct,
                setLastProduct,
                subscriberName,
                setSubscriberName,
                selectedGroups,
                setSelectedGroups,
                orderTotal,
                setOrderTotal,
                myCash,
                setMyCash,
                myData,
                setMyData,
                subGroups,
                setSubGroups,
                iMinutesData,
                setIMinutesData,
                msisdnConfirmed,
                setMsisdnConfirmed,
                orderConfirmed,
                setOrderConfirmed,
                showSubs,
                setShowSubs,
                subGroup,
                setSubGroup,
                errorMsg,
                setErrorMsg,
                orderProgress,
                setOrderProgress,
                orderOk,
                setOrderOk,
                orderResponse,
                setOrderResponse,
                topUpCountries,
                setTopUpCountries,
                cachedSteps,
                setCachedSteps,
                showMinutes,
                setShowMinutes,
                topTitle,
                setTopTitle
            }}
        >
            {loading && <Spinner type="overlay" />}
            {children}
        </TopUpContext.Provider>
    )

}

export { TopUpContext, TopUpProvider };
