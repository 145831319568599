import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './containers/App';
import './assets/sass/app.scss';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

import { MainContextProvider } from "./components/contexts/MainContext";


const MCPosApp = () => (
    <Router>
        <MainContextProvider>
            <App />
        </MainContextProvider>
    </Router>
);

var mountNode = document.getElementById('app');
ReactDOM.render(<MCPosApp />, mountNode);
