import { useRef, useState, useContext } from 'react';
import Header from '../../layout/Header';
import MCpos from '../../../services/MCpos';
import ValidatorService from '../../../services/validation-service';
import mc from '../../../resources/mycallsettings';
import { TextInput, Alert, ErrorInfo, InlineSpinner } from '../../layout/Elements';
import { Translations } from '../../../resources/translations.js';
import OrderOk from './OrderOk';
import CustomerDetails from './CustomerDetails';
import { MainContext } from '../../contexts/MainContext';

const ReplaceSim = () => {

    const {
        locale
    } = useContext(MainContext);

    const [orderProgress, setOrderProgress] = useState(false);
    const [sidOk, setSidOk] = useState(false);
    const [orderOk, setOrderOk] = useState(false);
    const [sidInfo, setSidInfo] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [formValues, setFormValues] = useState({});

    const inputRef = useRef(null);

    const handleInput = ({target: {name, value}}) => {
        setFormValues({...formValues, [name]: value});
        setErrorMsg('');
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (event.target.name === 'msisdn') {
                getSidInfo(event);
            }
            if (event.target.name === 'simNumber') {
                orderSim();
            }
        }
    }

    const orderSim = () => {
        setOrderProgress(true);
        setErrorMsg('');
        if (ValidatorService.validateSim(formValues.simNumber)) {
            MCpos.activateNewSim(formValues.msisdn, formValues.simNumber).then((response) => {
                if (response.data.STATUS === 404 || response.data.STATUS === '1') {
                    setErrorMsg(response.data.description ? response.data.description : response.data.DESCRIPTION);
                    setOrderProgress(false);
                } else if (response.status === 500) {
                    setErrorMsg(response.data.message);
                    setOrderProgress(false);
                } else {
                    setSidInfo(response.data);
                    setOrderOk(true);
                    setOrderProgress(false);
                }
            }).catch((error) => {
                setErrorMsg(`Server error ${error}`);
            });
        } else {
            setErrorMsg(Translations[locale].simNotValid);
            setOrderProgress(false);
        }
    }

    const getSidInfo = (event) => {
        let msisdn = event.target.value;
        if (msisdn !== '') {
            setOrderProgress(true);
            setErrorMsg('');
            if (ValidatorService.validateMsisdn(msisdn)) {
                MCpos.getUserInfo(msisdn).then((response) => {
                    if (response.STATUS === 404) {
                        setErrorMsg(response.data.description);
                        setOrderProgress(false);
                    } else if (response.status === 500) {
                        setErrorMsg(JSON.stringify(response.data));
                    } else {
                        setSidOk(true);
                        setFormValues({msisdn: msisdn});
                        setSidInfo(response.data);
                        setOrderProgress(false);
                        inputRef.current.focus();
                    }
                }).catch((error) => {
                    setErrorMsg(`Server error ${error}`);
                    setOrderProgress(false);
                });
            } else {
                setErrorMsg(Translations[locale].numberNotValid);
                setOrderProgress(false);
            }
        }
    }

    if (orderOk) {
        return <OrderOk />
    }

    return (
        <div className="page" id="ReplacementSim">
            <Header backLink="prepaidmenu" />
            <div className="main-content">
                <div className="container-fluid">
                    <h1 className="title">{Translations[locale].replacementSimCard}</h1>
                    <div className="row">

                        <div className="col-sm-6">
                            <TextInput  name="msisdn"
                                        autoFocus type="tel"
                                        className="form-control"
                                        onChange={(event) => handleInput(event)}
                                        value={formValues.msisdn ?? ''}
                                        onBlur={(event) => getSidInfo(event)}
                                        label={Translations[locale].mobileNumber}
                                        required="yes"
                                        onKeyPress={(event) => handleKeyPress(event)}
                            />
                            <TextInput 	name="simNumber"
                                          type="tel"
                                          className="form-control"
                                          label={Translations[locale].newSimCardNumber}
                                          onChange={(event) => handleInput(event)}
                                          value={formValues.simNumber ?? ''}
                                          disabled={sidOk ? false : "disabled"}
                                          onKeyPress={(event) => handleKeyPress(event)}
                                          ref={inputRef}
                            />

                            <p className="mb-3">
                                <button onClick={() => orderSim()} className="btn btn-success" disabled={!sidOk}>
                                    {Translations[locale].confirmReplacement}
                                    {orderProgress && <span className="ms-2"><InlineSpinner /></span>}
                                </button>
                            </p>
                            <Alert visible={!!errorMsg}>
                                {errorMsg}
                            </Alert>

                            {errorMsg === mc.genericDBerror && <ErrorInfo />}

                        </div>

                        <div className="col-sm-6">
                            <CustomerDetails customerInfo={sidInfo} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReplaceSim;
