import { useContext } from 'react';
import { Translations } from "../../../resources/translations";
import { Alert, CountryInput, ErrorInfo, Errorlist, Spinner, TextInput } from "../../layout/Elements";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import mc from "../../../resources/mycallsettings";
import Joi from "joi-browser";
import {
    AddressRule,
    DateRulePorting,
    DefaultValidationOptions,
    EmailRule,
    MSISDNRule,
    ReqFieldRule
} from "../../../resources/validation-rules";
import MCpos from '../../../services/MCpos';
import ValidatorService from "../../../services/validation-service";
import OrderOk from "./OrderOk";
import SelectId from "./SelectId";
import GetIdNumber from "./GetIdNumber";
import { useEffect, useState } from "react";
import moment from "moment";
import { MainContext } from "../../contexts/MainContext";

const validateForm = () => {

    const {
        locale
    } = useContext(MainContext);

    const [errorMsg, setErrorMsg] = useState('');
    const [errorList, setErrorList] = useState(null);
    const [orderProgress, setOrderProgress] = useState(false);
    const [orderOk, setOrderOk] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [idType, setIdType] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [idConfirmed, setIdConfirmed] = useState(false);
    const [portDate, setPortDate] = useState(null);
    const [custUnderAge, setCustUnderAge] = useState(false);

    useEffect(() => {
        setFormValues({
            msisdn: '',
            newSim: '',
            icc: '',
            idNumber: '',
            firstName: '',
            lastName: '',
            address: '',
            email: '',
            zip: '',
            city: '',
            country: 'Norge',
            language: 'NO',
        });
        setPortDate(getDefaultPortDate());
    }, []);

    const createPortingOrder = () => {
        setOrderProgress(true);
        setErrorMsg('');

        let order = {
            ORDER_TYPE: 'NEW',
            CUSTOMER: {
                FIRST_NAME: formValues.firstName,
                LAST_NAME : formValues.lastName,
                EMAIL:      formValues.email,
                BIRTHDATE:  moment.parseZone(birthDate).format('DD.MM.YYYY'),
                IDTYPE:     idType.navicode,
                IDNUMBER:   formValues.idNumber,
                ADDRESS1:   formValues.address,
                ZIP:        formValues.zip,
                CITY:       formValues.city,
                COUNTRY:    formValues.country,
                HAS_SSN:    '0',
            },
            PHONE: {
                MSISDN:      formValues.msisdn,
                NUMBER_TYPE: 'PORTING',
                PORT_DATE:   moment.parseZone(portDate).format('DD.MM.YYYY'),
                SIMNO:       formValues.newSim,
            },
            PAYMENT: {
                PAYMENT_TYPE: 'PREPAID',
                INVOICE_TYPE: 'MAIL',
            },
            SALES: {
                CLIENT:  'McPos',
                USERNAME: MCpos.user,
                LOCATION: MCpos.location
            },
            CURRENCY: 'NOK',
            TOTAL_FEE: 0
        }

        if (validateForm(order) === false) {
            return false;
        }

        MCpos.purchaseOrder(order).then((response) => {
            if (response.data.STATUS === 404 || response.status === 500 || response.status === 400) {
                let errorMessage = '';
                if (response.data.description) {
                    errorMessage = response.data.description;
                }
                if (response.data.MSR_ERROR_REASON) {
                    errorMessage = response.data.MSR_ERROR_REASON;
                }
                if (response.data.message) {
                    errorMessage = response.data.message;
                }
                setErrorMsg(errorMessage);
                setOrderProgress(false);
            } else {
                setOrderOk(true);
            }
        }).catch((error) => {
            setErrorMsg(`Server error ${error}`);
            setOrderProgress(false);
        });
    }

    const validateForm = (order) => {
        setErrorList(null);
        const schema = {
            CUSTOMER: Joi.object({
                FIRST_NAME: ReqFieldRule(Translations[locale].firstName, locale),
                LAST_NAME: ReqFieldRule(Translations[locale].lastName, locale),
                ZIP: ReqFieldRule(Translations[locale].zip, locale),
                CITY: ReqFieldRule(Translations[locale].city, locale),
                COUNTRY: ReqFieldRule(Translations[locale].country, locale),
                BIRTHDATE: DateRulePorting(Translations[locale].birthdate, locale),
                EMAIL: EmailRule(Translations[locale].email, locale),
                ADDRESS: AddressRule(Translations[locale].address, locale),
            }),
            PHONE: Joi.object({
                MSISDN: MSISDNRule(locale),
                PORT_DATE: DateRulePorting(Translations[locale].portDate, locale),
                SIMNO: ReqFieldRule(Translations[locale].newSimCardNumber, locale),
            })
        }
        let result = Joi.validate(order, schema, DefaultValidationOptions);
        if (result.error !== null) {
            let errors = ValidatorService.parseErrors(result.error.details);
            setErrorList(errors);
            setOrderProgress(false);
            return false;
        }
        return true;
    }

     const handleInput = ({target: {name, value}}) => {
         setFormValues({...formValues, [name]: value});
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (event.target.name === 'idNumber') {
                confirmId();
            }
        }
    }

    const handleDateChange = (date, inputName) => {
        setErrorMsg('');
        setCustUnderAge(false);
        if (inputName === 'birthDate') {
            setBirthDate(date);
            if (!ValidatorService.validateAge(date)) {
                setErrorMsg(Translations[locale].custUnderAge)
                setCustUnderAge(true);
            }
        } else {
            setPortDate(date);
        }
    }

    const getIdType = (idType) => {
        setIdType(idType);
    }

    const confirmId = () => {
        if (idNumber) {
            setIdConfirmed(true);
        }
    }

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    }

    const addWeekdays = (date, days) => {
        date = moment(date);

        while (days > 0) {
            date = date.add(1, 'days');
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                days -= 1;
            }
        }

        return date.toDate();
    }

    const getDefaultPortDate = () => {
        // Min port days in backend is 20 work hours (8 hours in a day) = 2.5 days excluding weekends
        return addWeekdays(new Date(), mc.minPortingDays);
    }

    if (orderOk) {
        return <OrderOk />
    }
    if (!idType) {
        return <SelectId onClick={(idType) => getIdType(idType)} />
    } else if (idType !== '' && idConfirmed === false) {
        return <GetIdNumber
            value={formValues.idNumber}
            onClick={() => confirmId()}
            onChange={(event) => handleInput(event)}
            onKeyPress={(event) => handleKeyPress(event)}
            idType={formValues.idType}
        />
    }

    if (orderProgress) {
        return (
            <Spinner type="overlay" />
        )
    }

    return (
        <div className="main-content">
            <div className="container-fluid">
                <h1 className="title">{Translations[locale].porting}</h1>
                <div className="row">

                    <div className="col-sm-6">

                        <TextInput type="tel" autoFocus="yes" name="msisdn" value={formValues.msisdn} required="yes" onChange={(event) => handleInput(event)} label={Translations[locale].mobileNumber} />
                        <TextInput type="tel" name="newSim" value={formValues.newSim} required="yes" onChange={(event) => handleInput(event)} label={Translations[locale].newSimCardNumber} />

                        <div className="row mt-4">
                            <div className="col-sm-6">
                                <TextInput readOnly="yes" name="idType" value={Translations[locale][idType.transCode]} required="yes" label={Translations[locale].idType} />
                            </div>
                            <div className="col-sm-6">
                                <TextInput readOnly="yes" name="idNumber" value={formValues.idNumber} required="yes" label={Translations[locale].idNumber} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">{Translations[locale].portDate} *</label>
                            <DatePicker
                                className="form-control"
                                selected={portDate}
                                onChange={(date) => handleDateChange(date, 'portDate')}
                                minDate={getDefaultPortDate()}
                                maxDate={addDays(new Date() ,mc.maxPortingDays)}
                                filterDate={isWeekday}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat="dd/MM/yyyy"/>
                        </div>
                        <p className="mt-5"><button onClick={() => createPortingOrder()} className="btn btn-success w-100 w-md-auto mb-4" disabled={orderProgress || custUnderAge}>{Translations[locale].confirmPorting}</button></p>

                        <Alert visible={!!errorMsg}>
                            {errorMsg}
                        </Alert>

                        {errorMsg === mc.genericDBerror && <ErrorInfo />}

                        <Errorlist heading={Translations[locale].validationError} list={errorList} item="message" />
                    </div>

                    <div className="col-sm-6">
                        <div className="box">
                            <h2>{Translations[locale].customer}</h2>

                            <TextInput name="firstName" value={formValues.firstName} required="yes" onChange={(event) => handleInput(event)} label={Translations[locale].firstName} />

                            <TextInput name="lastName" value={formValues.lastName} required="yes" onChange={(event) => handleInput(event)} label={Translations[locale].lastName} />

                            <div className="mb-3">
                                <label className="form-label">{Translations[locale].birthdate} *</label>
                                <DatePicker
                                    className="form-control"
                                    selected={birthDate}
                                    onChange={(date) => handleDateChange(date, 'birthDate')}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd/MM/yyyy"/>
                            </div>

                            <TextInput name="address" value={formValues.address} onChange={(event) => handleInput(event)} label={Translations[locale].address} />

                            <div className="row">
                                <div className="col-sm-6">
                                    <TextInput name="zip" value={formValues.zip} required="yes" onChange={(event) => handleInput(event)} label={Translations[locale].zip} />
                                </div>
                                <div className="col-sm-6">
                                    <TextInput name="city" value={formValues.city} required="yes"  onChange={(event) => handleInput(event)} label={Translations[locale].city} />
                                </div>
                            </div>

                            <CountryInput name="country" value={formValues.country} label={Translations[locale].country} onChange={(event) => handleInput(event)} />

                            <TextInput name="email" value={formValues.email} onChange={(event) => handleInput(event)} label={Translations[locale].email} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default validateForm;
