import { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Languages } from '../../resources/languages';
import { Translations } from '../../resources/translations';
import { MainContext } from "../contexts/MainContext";
import useSetLang from "../hooks/useSetLang";

export const LangMenu = () => {

    const {
        locale,
    } = useContext(MainContext);

    const setLang = useSetLang();

    const [languageSelected, setLanguageSelected] = useState(false);

    const selectPrimaryLanguage = (language) => {
        setLang(language);
        setLanguageSelected(true);
    }

    if (languageSelected) {
        return <Redirect to="/location" />;
    }

    return (
        <div className="page page--lang-menu" id="LangMenu">
            <div className="container-fluid">
                <h1 className="title">{Translations[locale].chooseYourLanguage}</h1>

                <ul className="nav row justify-content-center">
                    {Object.keys(Languages).map(function (language) {
                        return (
                            <li key={language} className="col-lg-4">
                                <button
                                    type="button"
                                    onClick={() => selectPrimaryLanguage(language)}
                                    className="btn btn-lg btn-primary w-100 mb-5"
                                >
                                    {Translations[locale][Languages[language].name.toLowerCase()]}
                                </button>
                            </li>
                        );
                    }, this)}
                </ul>
            </div>
        </div>
    );
}

export default LangMenu;
