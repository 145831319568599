import mc from '../resources/mycallsettings';
import moment from 'moment';

export default class ValidatorService {
    
    static parseErrors(errors, depth = 0 )  {
        const parsedErrors = [];
        for (let error of errors) {
            if (!parsedErrors[error.path[depth]]) {
                parsedErrors[error.path[depth]] = { type: error.type, message: error.context.label };
            }
        }
        return parsedErrors;
    }

    static validateAge(birthDate) {
        return moment(birthDate).diff(moment(), 'years') * -1 >= mc.minCustAge;
    }

    static validateSim(simNumber) {
        return simNumber.length === 13 || simNumber.length === 20;
    }

    static validateMsisdn(msisdn) {
        return msisdn.length === 8;
    }

}