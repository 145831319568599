import { forwardRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Countries } from '../../resources/countries';
import mc from '../../resources/mycallsettings';

import MCpos from '../../services/MCpos';
import McPreloader from './../../assets/img/MCpreloader.gif';

export const MenuItem = ({ link, children }) => (
    <div className="col-sm-6">
        <Link to={link} className="btn btn-lg btn-primary w-100 mb-5">
            <Card type="shadowcard">{children}</Card>
        </Link>
    </div>
);

export const MenuListItem = ({ link, children }) => (
    <div className="col-sm-12 pt-2">
        <Link to={link}>
            <Card type="smallshadowcard">{children}</Card>
        </Link>
    </div>
);

export const ShadowCard = ({ children }) => (
    <div className="card shadowcard">
        <div className="card-body">{children}</div>
    </div>
);

export const ClickableShadowCard = ({ children }) => (
    <div className="card shadowcard h-100">
        <div className="card-body">{children}</div>
    </div>
);

const Card = ({ children }) => <span>{children}</span>;

export const TextInput = forwardRef((props, ref) => {
    const {
        name,
        label,
        required,
        type,
        tabindex,
        placeholder,
        value,
        onBlur,
        onFocus,
        onChange,
        onKeyPress,
        disabled,
        autoFocus,
        readOnly,
        autoComplete,
        maxLength
    } = props;
    return (
        <p className="mb-3">
            <label htmlFor={name} className="form-label">
                {label} {required ? ' *' : ''}
            </label>
            <input
                type={type}
                className="form-control"
                tabIndex={tabindex}
                id={name}
                name={name}
                placeholder={placeholder}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
                onKeyPress={onKeyPress}
                disabled={disabled}
                required={required}
                autoFocus={autoFocus}
                readOnly={readOnly}
                autoComplete={autoComplete}
                maxLength={maxLength}
                ref={ref}
            />
        </p>
    );
});

export const CountryInput = (props) => (
    <p className="mb-3">
        <label className="form-label" htmlFor={props.name}>{props.label} *</label>
        <select
            className="form-control country-select"
            tabIndex={props.tabindex}
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            disabled={props.disabled}
        >
            {Object.keys(Countries).map(function (key) {
                return (
                    <option key={key} value={Countries[key].name}>
                        {Countries[key].name}
                    </option>
                );
            })}
        </select>
    </p>
);

export const LanguageInput = (props) => (
    <p className="mb-3">
        <label className="form-label" htmlFor={props.name}>{props.label}</label>
        <select
            className="form-control country-select"
            tabIndex={props.tabindex}
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            disabled={props.disabled}
        >
            {Object.keys(mc.langCodes).map(function (key) {
                return (
                    <option key={key} value={key}>
                        {mc.langCodes[key].translations[MCpos.lang]}
                    </option>
                );
            })}
        </select>
    </p>
);

export const CheckBoxInput = (props) => (
    <Fragment>
        <input 
            className={`form-check-input ${props.inputClassName}`}
            tabIndex={props.tabindex}
            value={props.isChecked}
            id={props.name}
            name={props.name}
            type="checkbox"
            checked={props.isChecked}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onChange={props.onChange}
            disabled={props.disabled}
        />
        <label className="form-check-label" htmlFor={props.name}>{props.label}</label>
    </Fragment>
);

export const Fieldset = (props) => <fieldset disabled={props.disabled}>{props.children}</fieldset>;

export const Alert = (props) => {
    if (props.children && props.visible) {
        return (
            <div className="alert alert-warning" role="alert">
                <p>{props.children}</p>
            </div>
        );
    }
    return '';
};

export const Info = (props) => (
    <div className={`alert alert-${props.statusCode ===  '0' ? 'success' : 'warning'}`} role="alert">
        <p>{props.children}</p>
    </div>
);

export const Errorlist = ({ heading, list, item }) => {
    if (list) {
        return (
            <div className="alert alert-warning" role="alert">
                <h5>{heading}</h5>
                <ul>
                    {Object.keys(list).map(function (key) {
                        return <li key={key}>{list[key][item]}</li>;
                    })}
                </ul>
            </div>
        );
    }
    return '';
};

export const ErrorInfo = () => {
    return (
        <div className="alert alert-warning" role="alert">
            <h5>Unspecified error messages</h5>
            <p>
                You have received an unspecified error message and need to contact customer service or support to figure
                out what went wrong.
            </p>
            <p>In most cases, this means that whatever you just tried to do did not work.</p>
        </div>
    );
};

export const ProductSelector = (props) => (
    <p className="mb-4">
        <button type="button" value={props.data} onClick={props.onClick} className="btn btn-outline-primary">
            {props.children}
        </button>
    </p>
);

export const ThankYou = (props) => (
    <div className="main-content">
        <div className="container-fluid">
            <h1 className="title">{props.title}</h1>
            <p className="lead text-center mb-5">{props.summary}</p>
            <p className="text-center">
                <Link to={props.buttonLink}>
                    <button className="btn btn-outline-primary">{props.buttonText}</button>
                </Link>
            </p>
        </div>
    </div>
);

export const Spinner = ({type}) => (
    <div className={`spinner spinner--${type ?? 'default'}`}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
);

export const InlineSpinner = () => (
    <span className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </span>
);
