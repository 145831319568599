import { useContext } from 'react';
import { Alert, TextInput } from '../../layout/Elements';
import { Translations as T } from '../../../resources/translations';
import { TopUpContext } from "../../contexts/TopUpContext";
import MCpos from '../../../services/MCpos';
import ValidatorService from "../../../services/validation-service";
import { MainContext } from "../../contexts/MainContext";

const GetMsisdn = () => {
    const {
        locale,
    } = useContext(MainContext);

    const {
        msisdn,
        errorMsg,
        setErrorMsg,
        setMsisdnConfirmed,
        setLoading,
        setOrderProgress,
        setMsisdn,
        setMyCash,
        setMyData,
        setIMinutesData,
        setSubGroups,
        setSubscriberName,
        setLastProduct,
        setTopUpCountries
    } = useContext(TopUpContext);

    /**
     * Function containing error statuses,
     * which are not caught by try-catch
     * @param {Object} response 
     * @returns {Boolean}
     */
    const isNormalError = (response) => {
        return response.status === 404 ||
        response.status === 500 ||
        response.status === 403 ||
        response.status === '-1';
    };

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            await confirmMsisdn();
        }
    }

    const confirmMsisdn = async () => {
        setLoading(true);
        let trimmedMsisdn = msisdn.trim();
        if (ValidatorService.validateMsisdn(trimmedMsisdn)) {
            MCpos.validatePrepaidNumber(trimmedMsisdn)
                .then(async (response) => {
                    if (
                        response.data.IS_MYCALL_NUMBER !== '1' ||
                        response.data.IS_ACTIVE !== '1' ||
                        response.data.IS_PREPAID !== '1'
                    ) {
                        let error = '';
                        if(response.data.IS_MYCALL_NUMBER !== '1') {
                            error=T[locale].isNotMycallNumber;
                        } else if(response.data.IS_ACTIVE !== '1') {
                            error=T[locale].isNotActiveNumber;
                        } else if(response.data.IS_PREPAID !== '1') {
                            error=T[locale].isNotPrepaidNumber;
                        }
                        setErrorMsg(error);
                        setMsisdnConfirmed(false);
                    } else {
                        setErrorMsg('');
                        await getUserInfo(trimmedMsisdn);
                        await getCurrentProducts(trimmedMsisdn);
                        await getTopupCountries(trimmedMsisdn);
                        await getProducts(trimmedMsisdn);
                        setMsisdnConfirmed(true);
                        setMsisdn(trimmedMsisdn);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setErrorMsg('Server error: ' + error);
                    setOrderProgress(false);
                    setLoading(false);
                });
        } else {
            setErrorMsg(T[locale].numberNotValid);
            setLoading(false);
        }
    }

    const getCountries = async () => {
        return MCpos.getCountries()
            .then((response) => {
                if (isNormalError(response.data)) {
                    setErrorMsg(response.data.description ? response.data.description : response.data.message);
                } else {
                    return response.data;
                }
            })
            .catch((error) => {
                setErrorMsg('Server error: ' + error);
                setOrderProgress(false);
            });
    }

    const getProducts = async (msisdn) => {
        const availableGroups = await MCpos.getTopupGroups(msisdn).then(res => res.data);
        await MCpos.getTopupProducts(msisdn)
            .then((response) => {
                if (isNormalError(response.data)) {
                    setErrorMsg(response.data.description ? response.data.description : response.data.message);
                    setMsisdnConfirmed(false);
                } else {
                    MCpos.fixProducts(response.data, availableGroups);
                    setMyCash(MCpos.products.myCash);
                    setMyData(MCpos.products.myData);
                    setIMinutesData(MCpos.products.iMinutesData);
                    setSubGroups(MCpos.subGroups);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorMsg('Server error: ' + error);
                setOrderProgress(false);
            });
    }

    const getUserInfo = async (msisdn) => {
        MCpos.getUserInfo(msisdn)
            .then((response) => {
                if (isNormalError(response.data)) {
                    let error = response.data.description ? response.data.description : response.data.message;
                    setErrorMsg(error);
                    setMsisdnConfirmed(false);
                } else {
                    const firstName = response.data.ACCOUNT_FIRSTNAME ?? '';
                    const lastName = response.data.ACCOUNT_LASTNAME ?? '';

                    setSubscriberName(`${firstName} ${lastName}`);
                }
            }).catch((error) => {
            setErrorMsg('Server error: ' + error);
            setOrderProgress(false);
        });
    }

    const getCurrentProducts = async (msisdn) => {
        MCpos.getCurrentProducts(msisdn)
            .then((response) => {
                if (isNormalError(response.data)) {
                    setErrorMsg(response.data.description ? response.data.description : response.data.message);
                    setMsisdnConfirmed(false);
                } else {
                    const lastProduct = response.data.filter(product => {
                        return product.productCategory === 'TOPUP' || product.productCategory === 'PACKAGE'
                    })[0];
                    setLastProduct(lastProduct);
                }
            }).catch((error) => {
                setErrorMsg('Server error: ' + error);
                setOrderProgress(false);
        });
    }

    const getTopupCountries = async (msisdn) => {
        getCountries().then((response) => {
            const allCountries = response;
            MCpos.getTopupCountries(msisdn)
                .then((response) => {
                    if (isNormalError(response.data)) {
                        setErrorMsg(response.data.description ? response.data.description : response.data.message);
                        setMsisdnConfirmed(false);
                    } else {
                        MCpos.fixTopupCountries(response.data, allCountries, locale);
                        setTopUpCountries(MCpos.topupCountryData);
                    }
                })
                .catch((error) => {
                    setErrorMsg('Server error: ' + error);
                    setOrderProgress(false);
                });
        });
    }

    return (
        <div id="GetMsisdn">
            <div className="row justify-content-center align-items-end">
                <div className="col-sm-6">
                    <TextInput
                        autoFocus
                        type="tel"
                        name="msisdn"
                        label={T[locale].yourMycallNumber}
                        value={msisdn}
                        onChange={(event) => setMsisdn(event.target.value)}
                        onKeyPress={(event) => handleKeyPress(event)}
                    />
                </div>
                <div className="col-sm-auto">
                    <button className="btn btn-success w-100 w-md-auto mb-3" onClick={confirmMsisdn}>
                        {T[locale].confirm}
                    </button>
                </div>
                <div className="col-sm-12">
                    <Alert visible={!!errorMsg}>{errorMsg}</Alert>
                </div>
            </div>
        </div>
    );
};

export default GetMsisdn;
