import 'moment/locale/nb'; // TODO: Remove?
import Header from '../../layout/Header';
import 'react-datepicker/dist/react-datepicker.css'; // TODO: Remove?
import ValidateForm from "./ValidateForm";

const Porting = () => (
    <div className="page" id="porting">
        <Header backLink="prepaidmenu" />
        <ValidateForm />
    </div>
)

export default Porting;
