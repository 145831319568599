import { useContext } from 'react';
import { Translations as T } from '../../../resources/translations';
import MCpos from '../../../services/MCpos';
import { MainContext } from "../../contexts/MainContext";

const CartProduct = (props) => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        category,
        product,
        onClick,
        toggleIsFree,
        toggleIssueAsVoucher,
        orderConfirmed,
    } = props;

    return (
        <div className="cart-item">
            <div className="d-flex">
                <div className="flex-grow-1">
                    <h3>{category}:</h3>
                    <dl className="horizontal-dl">
                        <dt>{T[locale].product}: </dt>
                        <dd>{MCpos.fixProductDesc(product, locale)}</dd>
                        <dt>{T[locale].price}: </dt>
                        <dd>Kr {product.userPrice},-</dd>
                    </dl>
                    {/*<p className="mb-0">{MCpos.fixProductDesc(product, locale)}</p>*/}
                    {/*<p className={product.isFree ? 'cart-item__crossed-out' : null}>Kr {product.userPrice},-</p>*/}
                </div>
                <p className="mb-0">
                    {onClick ?
                        <button className="btn-close" type="button" onClick={() => onClick(product.topupCategory)}>
                            <span className="visually-hidden">Lukk</span>
                        </button>
                        : ''}
                </p>
            </div>
            {toggleIsFree && (
                <div className="cart-item__selections">
                    <label className={product.isFree ? 'active-checkbox' : null}>
                        <input
                            checked={product.isFree}
                            onChange={(e) => toggleIsFree(product.topupCategory)}
                            name="isFree"
                            type="checkbox"
                        />
                        {T[locale].freeProduct}
                    </label>
                    <label className={`${product.issueAsVoucher ? 'active-checkbox' : null} ml-lg-2`}>
                        <input
                            checked={product.issueAsVoucher}
                            onChange={(e) => toggleIssueAsVoucher(product.topupCategory)}
                            name="issueAsVoucher"
                            type="checkbox"
                        />
                        {T[locale].issueAsVoucher}
                    </label>
                </div>
            )}
            {orderConfirmed && (
                <ul className="my-0">
                    {product.isFree && <li>{T[locale].freeProduct}</li>}
                    {product.issueAsVoucher && <li>{T[locale].issueAsVoucher}</li>}
                </ul>
            )}
        </div>
    );
};

export default CartProduct;
