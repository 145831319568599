import { useContext } from 'react';
import { ClickableShadowCard } from '../../../layout/Elements';
import { MainContext } from "../../../contexts/MainContext";

const Step1ProductCard = ({ country, changeStep1 }) => {
    const {
        locale,
    } = useContext(MainContext);
    return (
        <div className="col-sm-6 col-md-4 text-center pt-4 mb-2">
            <div className="cardpointer h-100" onClick={() => changeStep1(country.countryCode)}>
                <ClickableShadowCard>
                    <h3>{country.countryName.filter((obj) => obj.lang === locale.toUpperCase())[0].text}</h3>
                </ClickableShadowCard>
            </div>
        </div>
    );
};

export default Step1ProductCard;
