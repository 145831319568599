import mc from '../resources/mycallsettings';

export const Translations = {
	no: {
        /** Product Types */
        'DATA': {
            category: 'Data',
        },
        'SALDO': {
            category: 'Saldo',
        },
        'PACKAGE': {
            category: 'Pakke',
        },
        'TOPUP': {
            category: 'Internasjonale Minutter',
        },
        /** Subscription Types */
        'MyCall EU+': {
            Info: '30 dagers pakke med ringeminutter til EU, EØS, USA & Canada.',
            TopTitle: 'Mycall EU+ Pakker',
        },
        'MyCall Norge': {
            Info: '30 dagers pakke med ubegrenset ringeminutter i Norge.',
            TopTitle: 'Norge Pakker',
        },
        'Mycall Ukraine': {
            Info: '30 dagers pakke med ringeminutter til Ukraine.',
            TopTitle: 'Ukraine Pakker',
        },
        'MyCall Global': {
            'Info': '30 dager pakke med ringeminutter til Afrika, Midtøsten og Asia.',
            'TopTitle': 'Mycall Global Pakker',
        },
        'Mycall Care+': {
            Info: '30 dagers pakke med ubegrenset ringeminutter i Norge.',
            TopTitle: 'Mycall Care+ Pakker',
        },
        emptyString: '',
        chooseYourLanguage: 'Velg et språk',
        language: 'Språk',
        showEngLangName: 'Vis språk på engelsk',
        showNativeLangName: 'Vis språk med lokalt navn',
        howCanWeHelpYou: 'Hva kan vi hjelpe deg med?',
        back: 'Tilbake',
        prepaid: 'Kontantkort',
        startPackage: 'Startpakke',
        topUp: 'Påfylling',
        recentTopUps: 'Nylige påfyllinger',
        login: 'Logg inn',
        logout: 'Logg ut',
        username: 'Brukernavn',
        password: 'Passord',
        forgotPassword: 'Glemt passordet',
        missingUserAndPasswd: 'Brukernavn og passord må skrives inn',
        missingAccessForApp: 'Du mangler tilgang til Mycall Point of Sale',
        toggleLanguage: 'Endre språk',
        newSimCard: 'Nytt SIM-kort',
        replacementSimCard: 'Erstatnings SIM-kort',
        transferNumber: 'Overfør nummer til Mycall',
        doYouWantTopup: 'Ønsker du å fylle på saldo?',
        topUpSimCard: 'Top-up SIM-kort',
        topUpSubHeading: 'Saldo, data og ringepakker',
        seePackages: 'Se pakker',
        noThanks: 'Nei takk',
        topupTotal: 'Totalt',
        dataPacks: 'Datapakker',
        mobileNumber: 'Mobilnummer',
        newMycallNumber: 'Nytt Mycall-nummer',
        yourMycallNumber: 'Ditt Mycall-nummer',
        simCardActiveFrom: 'SIM-kort er aktivt fra',
        newSimCardNumber: 'Nytt SIM-kort nummer',
        lastDigits: 'Tre siste siffer',
        activationCode: 'Aktiveringskode (13 siffer)',
        confirmReplacement: 'Bekreft erstatning',
        numberNotValid: 'Telefonnummer er ikke gyldig',
        simNotValid: 'SIM-kort nummer er ikke gyldig, skal være 13 eller 20 siffer',
        idType: 'ID-type',
        idNumber: 'ID-nummer',
        firstName: 'Fornavn',
        lastName: 'Etternavn',
        name: 'Navn',
        customer: 'Kunde',
        birthdate: 'Fødselsdag',
        address: 'Adresse',
        email: 'E-post',
        zip: 'Postnr',
        city: 'Poststed',
        country: 'Land',
        confirm: 'Bekreft',
        verifyIdentity: 'Bekreft identitet',
        makeSureToCheck: 'Vennligst sjekk kundens legitimasjon',
        enterIdNumber: 'Vær vennlig å skriv inn ID-nummer',
        yourSummary: 'Din oppsummering',
        bankCard: 'Bankkort',
        cash: 'Kontant',
        products: 'Produkter',
        totalPrice: 'Total pris',
        price: 'Pris',
        beautifulDay: 'Ønsker deg en vakker dag',
        thankYou: 'Takk for at du valgte å bli en Mycall-kunde :)',
        nextCustomer: 'Neste kunde',
        date: 'Dato',
        time: 'Tidspunkt',
        selectIdtype: 'Velg ID-type',
        asylCard: 'Asylkort',
        euCard: 'EU-kort',
        passport: 'Pass',
        driversLicense: 'Førerkort',
        next: 'Neste',
        saldo: 'Saldo',
        data: 'Data',
        to: 'til',
        subscription: 'Abonnement',
        porting: 'Overfør nummer til Mycall',
        portDate: 'Overføringsdato',
        confirmPorting: 'Bekreft overføring',
        payment: 'Betaling',
        paid: 'Kunden har betalt',
        done: 'Ferdig',
        validationError: 'Dette må du rette før bestilling',
        mandatoryField: 'Dette er et påkrevd felt',
        notValidPhone: 'Telefonnummer er påkrevd og må starte med 4 eller 9 og inneholde 8 siffer',
        notValidEmail: 'E-post adresse må være gyldig, eller så kan den være tom',
        notValidAddress: 'Adresse må være gyldig, og kan ikke bare være tall, eller så kan den være tom',
        notValidDate: 'Dato er ikke gyldig',
        notValidSim: 'Simnummer er påkrevd å må inneholde 13 eller 20 siffer',
        notValidSimStartPack: 'Simnummer er påkrevd å må inneholde 3 siste siffer, 13 eller 20 siffer',
        custUnderAge: 'Kunden er ikke gammel nok, må være over ' + mc.minCustAge,
        freeProduct: 'Gratis produkt',
        issueAsVoucher: 'Bruk kupong',
        internationalMinutes: 'Internasjonale Minutter',
        internationalMinutesInfo: 'Minutter for kunder med Global',
        minutesTo: 'minutter til',
        minutes: 'minutter',
        days: 'dager',
        selectAPackage: 'Velg en pakke',
        internationalMinutesTopTitle: 'Extra internasjonale minutter',
        selectCountry: 'Velg land',
        multiStepTwo: 'Velg data og minutter',
        multiStepThree: 'Velg EU minutter',
        dataAndMinutes: 'Data og Minutter',
        euMinutes: 'EU minutter',
        confirmOrder: 'Bekreft Ordre',
        select: 'Velg',
        other: 'Annen',
        shop: 'Butikk',
        chooseLocation: 'Velg posisjon',
        customerService: 'Kundeservice',
        choose: 'Velg',
        notFound: '404 - Side ikke funnet!',
        isNotMycallNumber: 'Nummeret er ikke et Mycall-nummer.',
        isNotActiveNumber: 'Nummeret er ikke et aktivt nummer.',
        isNotPrepaidNumber: 'Nummeret er ikke et "prepaid" nummer.',
        nameOfTopup: 'Navn på påfylling',
        status: 'Status',
        isVoucher: 'Er kupong',
        isFreeProduct: 'Er et gratis produkt',
        created: 'Opprettet',
        responsiblePosUser: 'Ansvarlig PoS-bruker',
        yes: 'Ja',
        no: 'Nei',
        statusInProgress: 'Prosesserer',
        statusFailed: 'Mislyktes',
        statusSuccess: 'Suksess',
        product: 'Produkt',
        lastProduct: 'Siste produkt',
        expired: 'utløpt',
        noExpiry: 'ingen utløpstid',
        loading: 'laster inn',
        none: 'ingen',
        norwegian: 'Norsk',
        english: 'Engelsk',
        mycallCare: 'Mycall Care',
        ukraine: 'Ukraine',
        packageApproved: 'Care Package er lagt til.',
        packageNotApproved: 'Vi kunne ikke legge til Care Package. Du kan prøve å legge til ved å bruke Påfylling siden.',
    },
	en: {
        /** Product Types */
        'DATA': {
            category: 'Data',
        },
        'SALDO': {
            category: 'Balance',
        },
        'PACKAGE': {
            category: 'Package',
        },
        'TOPUP': {
            category: 'International Minutes',
        },
        /** Subscription Types */
        'MyCall EU+': {
            Info: '30 day packages with calling minutes to EU, EØS, USA & Canada.',
            TopTitle: 'Mycall EU+ Packages',
        },
        'MyCall Norge': {
            Info: '30 day packages with unlimited calls in Norway.',
            TopTitle: 'Norge Packages',
        },
        'Mycall Ukraine': {
            Info: '30 day packages with calling minutes to Ukraine.',
            TopTitle: 'Ukraine Packages',
        },
        'MyCall Global': {
            Info: '30 days calling minutes to Africa, The Middle East and Asia.',
            TopTitle: 'Mycall Global Packages',
        },
        'Mycall Care+': {
            Info: '30 day packages with unlimited calls in Norway.',
            TopTitle: 'Mycall Care+ Packages',
        },
        emptyString: '',
        chooseYourLanguage: 'Choose your language',
        language: 'Language',
        showEngLangName: 'Show language in English',
        showNativeLangName: 'Show language in local language',
        howCanWeHelpYou: 'How can we help you?',
        back: 'Back',
        prepaid: 'Prepaid',
        startPackage: 'Starter pack',
        topUp: 'Top-up',
        recentTopUps: 'Recent topups',
        login: 'Login',
        logout: 'Log out',
        username: 'Username',
        password: 'Password',
        forgotPassword: 'Forgot your password',
        missingUserAndPasswd: 'Please enter username and password',
        missingAccessForApp: 'You do not have access to Mycall Point of Sale',
        toggleLanguage: 'Change language',
        newSimCard: 'New SIM card',
        lastDigits: 'Last three digits',
        activationCode: 'Activation code',
        replacementSimCard: 'Replacement SIM-kort',
        transferNumber: 'Transfer number to Mycall',
        doYouWantTopup: 'Do you want to top-up the new SIM-card?',
        topUpSimCard: 'Top-up SIM card',
        topUpSubHeading: 'Balance, data and calling packages',
        seePackages: 'See packages',
        noThanks: 'No thanks',
        topupTotal: 'Total',
        dataPacks: 'Data',
        to: 'to',
        mobileNumber: 'Mobile number',
        newMycallNumber: 'New Mycall number',
        yourMycallNumber: 'Your Mycall number',
        simCardActiveFrom: 'SIM-card active from',
        newSimCardNumber: 'New SIM-card number',
        confirmReplacement: 'Confirm replacement',
        numberNotValid: 'The phone number is not valid',
        simNotValid: 'The SIM card number is not valid, should be 13 or 20 digits',
        idType: 'ID type',
        idNumber: 'ID number',
        firstName: 'First name',
        lastName: 'Last name',
        name: 'Name',
        customer: 'Customer',
        birthdate: 'Date of Birth',
        address: 'Address',
        email: 'E-mail',
        zip: 'Zip code',
        city: 'City',
        country: 'Country',
        confirm: 'Confirm',
        verifyIdentity: 'Verify Identity',
        makeSureToCheck: 'Please make sure to check customers\'s ID',
        enterIdNumber: 'Please enter ID number',
        yourSummary: 'Your Summary',
        bankCard: 'Bank card',
        cash: 'Cash',
        products: 'Products',
        totalPrice: 'Total price',
        price: 'Price',
        beautifulDay: 'Have a beautiful day',
        thankYou: 'Thank you for being part of the Mycall team:)',
        nextCustomer: 'Next customer',
        date: 'Date',
        time: 'Time',
        selectIdtype: 'Select ID type',
        asylCard: 'Asyl Card',
        euCard: 'EU Card',
        passport: 'Passport',
        driversLicense: 'Drivers License',
        next: 'Next',
        saldo: 'Balance',
        data: 'Data',
        subscription: 'Subscription',
        porting: 'Transfer number',
        portDate: 'Transfer date',
        confirmPorting: 'Confirm porting',
        payment: 'Betaling',
        paid: 'Customer has paid',
        done: 'Done',
        validationError: 'This must be fixed before ordering',
        mandatoryField: 'This is a required field',
        notValidPhone: 'Phone number is required and must start with 4 or 9 and contain 8 digits',
        notValidEmail: 'e-mail address must be valid or can be left empty',
        notValidAddress: 'Address must be valid and cannot be only numbers, or can be left empty',
        notValidDate: 'Date is not valid',
        notValidSim: 'Simnumber is required and must contain 13 or 20 digits ',
        notValidSimStartPack: 'Simnumber is required and must contain last 3 digits, 13 or 20 digits ',
        custUnderAge: 'The customer is not old enough, must be older than ' + mc.minCustAge,
        freeProduct: 'Free product',
        issueAsVoucher: 'Issue as voucher',
        internationalMinutes: 'International Minutes',
        minutesTo: 'minutes to',
        minutes: 'minutes',
        days: 'days',
        selectAPackage: 'Select a package',
        internationalMinutesTopTitle: 'Extra international minutes',
        selectCountry: 'Select country',
        multiStepTwo: 'Select amount of data and minutes',
        multiStepThree: 'Select amount of EU minutes',
        dataAndMinutes: 'Data and Minutes',
        euMinutes: 'EU minutes',
        confirmOrder: 'Confirm Order',
        select: 'Select',
        other: 'Other',
        shop: 'Shop',
        chooseLocation: 'Choose your location',
        customerService: 'Customer Service',
        choose: 'Choose',
        notFound: '404 - Page not found!',
        isNotMycallNumber: 'The number is not a Mycall number.',
        isNotActiveNumber: 'The number is not an active number.',
        isNotPrepaidNumber: 'The number is not a prepaid number.',
        nameOfTopup: 'Name of topup',
        status: 'Status',
        isVoucher: 'Is Voucher',
        isFreeProduct: 'Is free product',
        created: 'Created',
        responsiblePosUser: 'PoS user responsible',
        yes: 'Yes',
        no: 'No',
        statusInProgress: 'In progress',
        statusFailed: 'Failed',
        statusSuccess: 'Success',
        product: 'Product',
        lastProduct: 'Last product',
        expired: 'expired',
        noExpiry: 'no expiry',
        loading: 'loading',
        none: 'none',
        norwegian: 'Norwegian',
        english: 'English',
        mycallCare: 'Mycall Care',
        ukraine: 'Ukraine',
        packageApproved: 'Care Package is successfully added.',
        packageNotApproved: 'We were unable to add Care Package. You can try to add using the Top-up page.',
    },
};
