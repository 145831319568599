import Joi from 'joi-browser';
import {Translations as T} from '../resources/translations.js';

// Mycall uses different date formats in different database calls.

export const ReqFieldRule = (fieldName, lang = 'en') => {
    return Joi.string().required().label(fieldName + ': ' + T[lang].mandatoryField);
};

export const MSISDNRule = (lang = 'en') => {
    return Joi.string().regex(/^[4|9][0-9]{7}$/g).label(T[lang].notValidPhone);
};

export const EmailRule = (fieldName, lang = 'en') => {
    return Joi.string().email().allow('').optional().label(fieldName + ': ' + T[lang].notValidEmail);    
};

export const AddressRule = (fieldName, lang = 'en') => {
    return Joi.string().regex(/^\d*([a-zA-Z]|[^\x00-\x7F]){1,}\d*/).allow('').optional().label(fieldName + ': ' + T[lang].notValidAddress);
};

export const BirthDateRule = (fieldName, lang = 'en') => {
    return Joi.string().isoDate().required().label(fieldName + ': ' + T[lang].notValidDate);
}

export const DateRulePorting = (fieldName, lang = 'en') => {
    return Joi.string().regex(/^[0-9]{2}[.]{1}[0-9]{2}[.]{1}[0-9]{4}$/).required().label(fieldName + ': ' + T[lang].notValidDate);
}

export const ICCRule = (lang = 'en') => {
    return Joi.string().regex(/^([0-9]{13}|[0-9]{20})$/g).required().label(T[lang].notValidSim);
}

export const ICCRuleStartPack = (lang = 'en') => {
    return Joi.string().regex(/^([0-9]{3}|[0-9]{13}|[0-9]{20})$/g).required().label(T[lang].notValidSimStartPack);
}

export const DefaultValidationOptions =  {
    abortEarly: false, // validates all fields at once
    allowUnknown: true // ignore fields with no rule
};