import { useEffect, useContext } from 'react';

import { TopUpContext } from "../../contexts/TopUpContext";
import { Translations as T } from "../../../resources/translations";

import TopUp from "./TopUp";
import { MainContext } from "../../contexts/MainContext";

const MainContent = () => {

    const {
        locale,
    } = useContext(MainContext);

    const { topTitle, setTopTitle } = useContext(TopUpContext);

    useEffect(() => {
        setTopTitle({
            top: T[locale]['topUpSimCard'],
            sub: T[locale]['topUpSubHeading'],
        });
    }, []);


    return (
        <div className="main-content">
            <div className="container-fluid">
                <h1 className="title">{topTitle.top}</h1>
                <h2 className="text-center mb-5">{topTitle.sub}</h2>
                <TopUp />
            </div>
        </div>
    );
}

export default MainContent;
