import { useContext } from 'react';
import MCpos from '../../services/MCpos';
import { MainContext } from "../contexts/MainContext";

function useSetLang() {

    const {
        setLocale
    } = useContext(MainContext);

    return (locale) => {
        MCpos.lang = locale;
        MCpos.cookieData.lang = locale;
        MCpos.setCookie();
        setLocale(locale)
    };

}

export default useSetLang;
