import { useContext, Fragment } from 'react';
import { ProductSelector } from "../../layout/Elements";
import MCpos from '../../../services/MCpos';
import { TopUpContext } from "../../contexts/TopUpContext";
import useTopUpUpdateCart from "../../hooks/useTopUpUpdateCart";
import { MainContext } from "../../contexts/MainContext";

const MyCash = ({ heading }) => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        selectedGroups,
        setSelectedGroups,
        myCash,
    } = useContext(TopUpContext);

    const [ handleOrderTotal ] = useTopUpUpdateCart();


const selectSaldo = (cash) => {
    cash.isFree = false;
    cash.issueAsVoucher = false;

    const orderItems = {
        ...selectedGroups,
        selectedSALDO: cash
    };
    setSelectedGroups(orderItems);
    handleOrderTotal(orderItems);
}

return (
    <Fragment>
        <h3>{heading}</h3>

        {Object.keys(myCash).map(function (key) {
            return (
                <ProductSelector
                    key={key}
                    data={myCash[key].productDescNopp}
                    onClick={() => selectSaldo(myCash[key])}
                >
                    {MCpos.fixProductDesc(myCash[key], locale)}
                </ProductSelector>
            );
        })}
    </Fragment>
    )

};

export default MyCash;
