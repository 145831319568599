import { Fragment, useContext } from 'react';
import Header from '../layout/Header';
import { Translations } from '../../resources/translations.js';

import { ThankYou } from '../layout/Elements';
import { MainContext } from '../contexts/MainContext';

const CustomThankYou = () => {

    const {
        locale,
    } = useContext(MainContext);

    return (
        <Fragment>
            <Header backLink="prepaidmenu" />
            <div className="container-fluid">
                <ThankYou
                    title={Translations[locale].beautifulDay}
                    summary={Translations[locale].thankYou}
                    buttonText={Translations[locale].nextCustomer}
                    buttonLink="/menu"
                />
            </div>
        </Fragment>
    )
};

export default CustomThankYou;
