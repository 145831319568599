import { useContext } from 'react';
import { Translations } from '../../../resources/translations';
import { TextInput } from '../../layout/Elements';
import { MainContext } from '../../contexts/MainContext';

const GetIdNumber = ({ value, onChange, onClick, onKeyPress }) => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="main-content">
            <div className="container-fluid">
                <h1 className="title">{Translations[locale].idNumber}</h1>
                <div className="row justify-content-center align-items-end">
                    <div className="col-sm-6">
                        <TextInput
                            autoFocus="yes"
                            type="text"
                            name="idNumber"
                            label={Translations[locale].idNumber}
                            value={value}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                        />
                    </div>
                    <div className="col-sm-auto">
                        <p className="mb-0">
                            <button type="button" className="btn btn-success mb-3 w-100 w-md-auto" onClick={onClick}>
                                {Translations[locale].confirm}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GetIdNumber;
