import { useContext } from 'react';
import MCpos from '../../../services/MCpos';
import { Translations as T } from '../../../resources/translations';
import MinuteProductCard from './MinuteProductCard';
import { TopUpContext } from "../../contexts/TopUpContext";
import { MainContext } from "../../contexts/MainContext";

const SelectMinute = ({ onClick }) => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        topUpCountries,
        iMinutesData
    } = useContext(TopUpContext);

    const finalIddData = MCpos.mapCountryName(topUpCountries, iMinutesData, locale);
    // sort by iddRank and by countryName
    finalIddData.sort((a, b) => {
        return a.iddRank - b.iddRank || a.countryName.localeCompare(b.countryName);
    });

    return (
        <div id="selectminute">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {Object.keys(finalIddData).map((key) => {
                                return (
                                    <MinuteProductCard
                                        key={key}
                                        product={finalIddData[key]}
                                        onClick={onClick}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="pt-4 mb-0">
                            <button type="button" className="btn btn-outline-primary" onClick={() => onClick()}>
                                {T[locale].back}
                            </button>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectMinute;
