import { useContext } from "react";
import { Translations as T } from '../../../../resources/translations';
import MCpos from '../../../../services/MCpos';
import Step1ProductCard from './Step1ProductCard'
import Step2ProductCard from './Step2ProductCard'
import Step3ProductCard from './Step3ProductCard'
import { TopUpContext } from "../../../contexts/TopUpContext";
import useTopUpSelectSub from "../../../hooks/useTopUpSelectSub";
import useTopUpUpdateCart from "../../../hooks/useTopUpUpdateCart";
import { MainContext } from "../../../contexts/MainContext";

const SelectMultiStepSubscription = ({ group, onClick }) => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        topUpCountries,
        setCachedSteps,
        cachedSteps,
        setTopTitle
    } = useContext(TopUpContext);

    const selectSub = useTopUpSelectSub();
    const [ removeProduct ] = useTopUpUpdateCart();


    const products = group?.products;
    const currentStep = cachedSteps?.[group.name]?.currentStep || 1;
    const selectedCountries = currentStep === 1 && MCpos.filterCountries(topUpCountries, products, locale);

    const selectedData =
        currentStep > 1 && products && MCpos.getCountryData(products, cachedSteps?.[group.name]?.countryCode);

    const cachedSub = cachedSteps?.[group.name]?.subscription;

    const selectMultiSub = (subscription, euAddon = null, groupName) => {

        subscription.additionalProductsArray = [];

        // Adding country minutes
        subscription?.productExtras?.[0]?.productAddons.map((addon) => {
            subscription.additionalProductsArray.push(addon?.productId);
        });

        // Adding eu minutes
        if(euAddon) {
            subscription.userPrice = subscription.userPrice + euAddon?.userPrice;
            subscription.additionalProductsArray.push(euAddon?.productId);
        }

        subscription.additionalProducts = subscription.additionalProductsArray.join(";");

        changeStepNr(1, groupName);
        selectSub(subscription);
    }

    const changeStep1 = (countryCode, groupName) => {

        setCachedSteps({
            ...cachedSteps,
            [groupName]: {
                ...cachedSteps?.[groupName],
                countryCode: countryCode,
                currentStep: 2,
            }
        });

        removeProduct('Sub');
    }

    const changeStep2 = (subscription, groupName) => {
        setCachedSteps({
            ...cachedSteps,
            [groupName]: {
                ...cachedSteps?.[groupName],
                subscription: subscription,
                currentStep: 3,
            }
        });
    }

    const scrollTop = () => {
        if (window.pageYOffset > 200) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const changeStepNr = (step, groupName) => {
        setCachedSteps({
            ...cachedSteps,
            [groupName]: {
                ...cachedSteps?.[groupName],
                currentStep: step,
            }
        });

        multiSubTitleChange(step);

        scrollTop();
    }

    const multiSubTitleChange = (step) => {
        if (step === 1) {
            setTopTitle({
                top: T[locale]['multiTopTitle'],
                sub: T[locale]['selectCountry'],
            });
        }
        if (step === 2) {
            setTopTitle({
                top: T[locale]['multiTopTitle'],
                sub: T[locale]['multiStepTwo'],
            });
        } else if (step === 3) {
            setTopTitle({
                top: T[locale]['multiTopTitle'],
                sub: T[locale]['multiStepThree'],
            });
        }
    }

    return (
        <div id="selectmultisub" className="mh-600">
            <div className="container-fluid mb-5">
                <ul className="steps">
                    <li className="step">
                        <div
                            className={
                                'numbercircle' +
                                (currentStep >= 1 ? ' done' : '') +
                                (currentStep === 1 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 1 ? changeStepNr(1, group.name) : undefined;
                            }}
                        >
                            <span>1</span>
                        </div>
                        <div
                            className={
                                'multi-steptext' +
                                (currentStep >= 1 ? ' done' : '') +
                                (currentStep === 1 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 3 ? changeStepNr(3, group.name) : undefined;
                            }}
                        >
                            <p className="mb-1">{T[locale].country}</p>
                            {currentStep > 1 && selectedData && (
                                <p className="mb-1">
                                    {MCpos.getCountryName(topUpCountries, selectedData?.[0]?.countryCode, locale)}
                                </p>
                            )}
                        </div>
                    </li>
                    <li className="step">
                        <div
                            className={
                                'numbercircle' +
                                (currentStep >= 2 ? ' done' : '') +
                                (currentStep === 2 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 2 ? changeStepNr(2, group.name) : undefined;
                            }}
                        >
                            <span>2</span>
                        </div>
                        <div
                            className={
                                'multi-steptext' +
                                (currentStep >= 2 ? ' done' : '') +
                                (currentStep === 2 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 2 ? changeStepNr(2, group.name) : undefined;
                            }}
                        >
                            <p className="mb-1">{T[locale].dataAndMinutes}</p>
                            {currentStep > 2 && cachedSub && (
                                <p className="mb-1">
                                    {MCpos.getSelectedDataAndMinutes(cachedSub)}
                                </p>
                            )}
                        </div>
                    </li>
                    <li className="step">
                        <div
                            className={
                                'numbercircle me-0' +
                                (currentStep >= 3 ? ' done' : '') +
                                (currentStep === 3 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 3 ? changeStepNr(3, group.name) : undefined;
                            }}
                        >
                            <span>3</span>
                        </div>
                        <div
                            className={
                                'multi-steptext me-0' +
                                (currentStep >= 3 ? ' done' : '') +
                                (currentStep === 3 ? ' current' : '')
                            }
                            onClick={() => {
                                currentStep > 3 ? changeStepNr(3, group.name) : undefined;
                            }}
                        >
                            <p>{T[locale].euMinutes}</p>
                        </div>
                    </li>
                </ul>
            </div>

            {currentStep === 1 && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                {Object.keys(selectedCountries).map((key) => {
                                    return (
                                        <Step1ProductCard
                                            key={key}
                                            country={selectedCountries[key]}
                                            changeStep1={(countryCode) => changeStep1(countryCode, group.name)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {currentStep === 2 && selectedData && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                {Object.keys(selectedData).map((key) => {
                                    return (
                                        <Step2ProductCard
                                            key={key}
                                            product={selectedData?.[key]}
                                            changeStep2={(product) => changeStep2(product, group.name)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {currentStep === 3 && (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row justify-content-center">
                                <Step3ProductCard
                                    cachedSub={cachedSub}
                                    selectMultiSub={selectMultiSub}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-12">
                    <p className="pt-4 mb-0">
                        <button type="button" className="btn btn-outline-primary" onClick={() => onClick()}>
                            {T[locale].back}
                        </button>
                    </p>

                </div>
            </div>
        </div>
    );
}

export default SelectMultiStepSubscription;
