import { useContext } from 'react';
import { ClickableShadowCard } from '../../layout/Elements';
import { Translations as T } from '../../../resources/translations';
import { MainContext } from "../../contexts/MainContext";

const ProductCard = ({ product, onClick }) => {

    const {
        locale,
    } = useContext(MainContext);

    return (
        <div className="col-sm-6 col-md-4 text-center pt-4 mb-2">
            <div className="cardpointer h-100" onClick={() => onClick(product)}>
                <ClickableShadowCard>
                    <h3>{product.productDescNopp}</h3>
                    <p>
                        {T[locale].price}: kr {product.userPrice}
                    </p>
                </ClickableShadowCard>
            </div>
        </div>
    );
};

export default ProductCard;
