const mc = {
    startPackPrice: 49,
    minPortingDays: 10,
    maxPortingDays: 30,
    minCustAge: 15,
    genericDBerror: 'Unhandled error',

    // Languages that MC Backend systems will use for customer communication
    langCodes: {
        NO: { translations: { no: 'norsk', en: 'Norwegian' }},
        EN: { translations: { no: 'engelsk', en: 'English' }},
        PL: { translations: { no: 'polsk', en: 'Polish' }},
        UA: { translations: { no: 'ukrainsk', en: 'Ukrainian' }},
    }
};

export default mc;