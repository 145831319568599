import { useContext } from 'react';
import { TopUpContext } from "../contexts/TopUpContext";

function useTopUpUpdateCart() {
    const {
        selectedGroups,
        setSelectedGroups,
        setOrderTotal
    } = useContext(TopUpContext);

    const handleOrderTotal = (orderItems) => {
        let sum = 0;

        const {selectedSALDO, selectedDATA, selectedPACKAGE, selectedTOPUP, selectedStartpack} = orderItems;

        if (selectedSALDO && !selectedSALDO.isFree) {
            sum += parseInt(selectedSALDO.userPrice);
        }
        if (selectedDATA && !selectedDATA.isFree) {
            sum += parseInt(selectedDATA.userPrice);
        }
        if (selectedPACKAGE && !selectedPACKAGE.isFree) {
            sum += parseInt(selectedPACKAGE.userPrice);
        }
        if (selectedTOPUP && !selectedTOPUP.isFree) {
            sum += parseInt(selectedTOPUP.userPrice);
        }
        if (selectedStartpack && !selectedStartpack.isFree) {
            sum += parseInt(selectedStartpack.userPrice);
        }

        setOrderTotal(sum);
    }

    const removeProduct = (topUpCategory) => {
        const selectedProperty = selectedGroups['selected' + topUpCategory];
        if (selectedProperty) {
            const orderItems = {
                ...selectedGroups,
                ['selected' + topUpCategory]: null
            };
            setSelectedGroups(orderItems);
            handleOrderTotal(orderItems);
        }
    }

    return [handleOrderTotal, removeProduct]

}

export default useTopUpUpdateCart;
