import { useContext } from 'react'
import { Link } from 'react-router-dom';
import Header from '../layout/Header';
import { Translations } from '../../resources/translations.js';
import { MainContext } from '../contexts/MainContext';

const NotFound = () => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="page">
            <Header backLink="prepaidmenu" />
            <div className="main-content">
                <div className="container-fluid">
                    <h1 className="title">{Translations[locale].notFound}</h1>
                    <p className="lead text-center">{Translations[locale].notFound}</p>
                    <p className="text-center">
                        <Link to="/">
                            <button className="btn btn-outline-primary">{Translations[locale].back}</button>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default NotFound;
