import { Component } from 'react';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div>
					<div className="container-fluid pt-4">
						<h1>Something went wrong ¯\_(ツ)_/¯</h1>
						<h2 className="py-4">Please send the text or a screenshot to your nearest developer!</h2>
						<p className="py-4">{this.state.error && this.state.error.toString()}</p>
						<p className="py-4">{this.state.errorInfo.componentStack}</p>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}
