import { useContext } from 'react';
import { Translations } from '../../../resources/translations';
import { MainContext } from '../../contexts/MainContext';

const CustomerDetails = ({ customerInfo }) => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="box">
            <h2>{Translations[locale].customer}</h2>
            <div className="row">
                <div className="col-sm-6">
                    <dl>
                        <dt>{Translations[locale].lastName}</dt>
                        <dd>{customerInfo.CUSTMER_LASTNAME}</dd>
                    </dl>
                </div>
                <div className="col-sm-6">
                    <dl>
                        <dt>{Translations[locale].firstName}</dt>
                        <dd>{customerInfo.CUSTOMER_FIRSTNAME}</dd>
                    </dl>
                </div>
                <div className="col-sm-12">
                    <dl>
                        <dt>{Translations[locale].birthdate}</dt>
                        <dd>{customerInfo.CUSTOMER_BIRTHDAY}</dd>
                    </dl>
                    <dl>
                        <dt>{Translations[locale].address}</dt>
                        <dd>{customerInfo.CUSTOMER_ADDRESS1}</dd>
                    </dl>
                </div>
                <div className="col-sm-6">
                    <dl>
                        <dt>{Translations[locale].zip}</dt>
                        <dd>{customerInfo.CUSTOMER_ZIP}</dd>
                    </dl>
                </div>
                <div className="col-sm-6">
                    <dl>
                        <dt>{Translations[locale].city}</dt>
                        <dd>{customerInfo.CUSTOMER_CITY}</dd>
                    </dl>
                </div>
                <div className="col-sm-12">
                    <dl>
                        <dt>{Translations[locale].email}</dt>
                        <dd>{customerInfo.CUSTOMER_EMAIL}</dd>
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default CustomerDetails;
