
const environment = process.env.APP_ENV ?? 'dev';

const environments = {
    dev: {
        dev: true,
        local: false,
        apiUrl: process.env.API_TEST_SERVER,
        clientId: process.env.OAUTH2_CLIENT_ID,
        version: process.env.VERSION ?? 'local',
        cookieName: 'MCPos',
        scopeName: 'mcpos',
    },
    prod: {
        dev: false,
        local: false,
        apiUrl: process.env.API_PROD_SERVER,
        clientId: process.env.OAUTH2_CLIENT_ID,
        version: process.env.VERSION ?? 'local',
        cookieName: 'MCPos',
        scopeName: 'mcpos',
    },
    stage: {
        dev: false,
        local: false,
        apiUrl: process.env.API_STAGE_SERVER,
        clientId: process.env.OAUTH2_CLIENT_ID,
        version: process.env.VERSION ?? 'local',
        cookieName: 'MCPos',
        scopeName: 'mcpos',
    },
    local: {
        dev: true,
        local: true,
        apiUrl: process.env.API_TEST_SERVER,
        clientId: process.env.OAUTH2_CLIENT_ID,
        version: process.env.VERSION ?? 'local',
        cookieName: 'MCPos',
        scopeName: 'mcpos',
    },
};

export default environments[environment];
