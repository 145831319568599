import { useState, useContext } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import MCpos from '../../services/MCpos';
import { Translations } from '../../resources/translations';
import { MainContext } from "../contexts/MainContext";

export const Location = ({ isPosAdmin, isPosStand }) => {
    const [location, setLocation] = useState(null);
    let history = useHistory();

    const {
        locale,
    } = useContext(MainContext);

    const toggleLocation = (location) => {
        setLocation(location);
    }

    const onNext = () => {
        if (location) {
            MCpos.setLocation(location);
            history.push('/menu');
        }
    }

    return (
        <div className="location">
            <div className="container-fluid location__content">

                <h1 className="title">{Translations[locale].chooseLocation}</h1>

                <form className="mb-4">
                    <label className={location === 'BRUGATA' ? 'active' : null}>
                        <input
                            onChange={() => toggleLocation('BRUGATA')}
                            checked={location === 'BRUGATA'}
                            type="checkbox"
                        />
                        Mycall {Translations[locale].shop} Brugata
                    </label>
                    <label className={location === 'STROEMMEN' ? 'active' : null}>
                        <input
                            onChange={() => toggleLocation('STROEMMEN')}
                            checked={location === 'STROEMMEN'}
                            type="checkbox"
                        />
                        Mycall {Translations[locale].shop} Strømmen
                    </label>
                    {(isPosAdmin || isPosStand) && <label className={location === 'STAND' ? 'active' : null}>
                        <input
                            onChange={() => toggleLocation('STAND')}
                            checked={location === 'STAND'}
                            type="checkbox"
                        />
                        Stand
                    </label>}
                    {(isPosAdmin || isPosStand) && <label className={location === 'KS' ? 'active' : null}>
                        <input
                            onChange={() => toggleLocation('KS')}
                            checked={location === 'KS'}
                            type="checkbox"
                        />
                        {Translations[locale].customerService}
                    </label>}
                    {(isPosAdmin || isPosStand) && <label className={location === 'OTHER' ? 'active' : null}>
                        <input
                            onChange={() => toggleLocation('OTHER')}
                            checked={location === 'OTHER'}
                            type="checkbox"
                        />
                        {Translations[locale].other}
                    </label>}
                </form>
                <p className="mb-0">
                    <Link className="btn btn-link" to="langmenu">{Translations[locale].back}</Link>
                    <button type="button" className="btn btn-success" disabled={!location} onClick={onNext}>
                        {Translations[locale].next}
                    </button>
                </p>
            </div>
        </div>
    );
}

export default withRouter(Location);
