import { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import config from '../../resources/config.js';
import MCpos from '../../services/MCpos';

import { Translations } from '../../resources/translations.js';
import { TextInput, Alert, Spinner } from '../layout/Elements';
import { MainContext } from "../contexts/MainContext";

export const Login = ({ setPosStand, setPosAdmin }) => {

    const {
        loading,
        setLoading,
        locale,
        loggedIn,
        setLoggedIn,
    } = useContext(MainContext);

    const [errorMsg, setErrorMsg] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const logOut = (userName = null) => {
        setLoading(true);
        MCpos.logOut(userName)
            .then(() => {
                setLoading(false);
                setLoggedIn(false);
            })
            .catch((e) => {
                setLoading(false);
                setLoggedIn(false);
            });
        MCpos.clearLogin();
    }
    
    const login = () => {
        setErrorMsg('');

        if (userName && password) {
            MCpos.login(userName, password)
                .then((response) => {
                    if (response.data.STATUS === '200') {
                        if (MCpos.checkAccessScope(response.data.scopeValue)) {

                            // If we are local we can use this logic below
                            MCpos.updateCookie(
                                MCpos.local ? response.data.accessToken : null,
                                response.data.accessTokenExpires,
                                MCpos.lang,
                                MCpos.native
                            );

                            MCpos.user = userName;
                            MCpos.accessToken = response.data.accessToken;
                            setLoggedIn(true);
                            setLoading(false);

                            if (response.data.scopeValue.includes('posstand')) {
                                setPosStand(true);
                            }
                            if (response.data.scopeValue.includes('posadmin')) {
                                setPosAdmin(true);
                            }
                        } else {
                            setErrorMsg(Translations[locale].missingAccessForApp);
                            logOut(userName);
                        }
                    } else {
                        setErrorMsg(response.data.MESSAGE);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setErrorMsg(`Server error: ${error}`);
                    setLoading(false);
                });
        } else {
            setErrorMsg(Translations[locale].missingUserAndPasswd);
            setLoading(false);
        }
    }

    const handleInput = ({target: {name, value}}) => {
        if (name === 'userName') {
            setUserName(value.toUpperCase());
        } else {
            setPassword(value);
        }
    }

    const handleKeyPress =({ key }) => {
        if (key === 'Enter') {
            login();
        }
    }

    if (loading) {
        return <Spinner type="overlay"/>;
    }

    if (loggedIn) {
        return <Redirect to="/langmenu" />;
    }

    return (
        <div id="payment" className="login">
            <div className="container-fluid narrow">
                <h1 className="title">Point of Sale</h1>

                <TextInput
                    name="userName"
                    label={Translations[locale].username}
                    value={userName ?? ''}
                    onChange={(event) => handleInput(event)}
                    onKeyPress={(event) => handleKeyPress(event)}
                />

                <TextInput
                    type="password"
                    name="password"
                    label={Translations[locale].password}
                    value={password ?? ''}
                    onChange={(event) => handleInput(event)}
                    onKeyPress={(event) => handleKeyPress(event)}
                />

                <p className="mt-4">
                    <button
                        onClick={() => login()}
                        type="button"
                        className="btn btn-success w-100"
                        disabled={loading}
                    >
                        {Translations[locale].login}
                    </button>
                </p>

                <Alert visible={!!errorMsg}>{errorMsg}</Alert>

                <p className="version">Version: {config.version}</p>
            </div>
        </div>
    );
}

export default Login;
