import { useContext, Fragment } from 'react';
import { Translations as T } from "../../../resources/translations";
import CartProduct from "./CartProduct";
import useTopUpUpdateCart from "../../hooks/useTopUpUpdateCart";
import { MainContext } from "../../contexts/MainContext";
import { TopUpContext } from "../../contexts/TopUpContext";

const Cart = () => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        msisdn,
        lastProduct,
        selectedGroups,
        subscriberName,
        orderTotal,
        setSelectedGroups,
        setOrderConfirmed,
        orderConfirmed,
        setTopTitle
    } = useContext(TopUpContext);

    const [ handleOrderTotal, removeProduct ] = useTopUpUpdateCart();

    const orderConfirmEnabled = selectedGroups.selectedSALDO || selectedGroups.selectedDATA || selectedGroups.selectedPACKAGE || selectedGroups.selectedTOPUP;

    const toggleIsFree = (topUpCategory) => {
        const selectedProperty = selectedGroups['selected' + topUpCategory];
        selectedProperty.isFree = !selectedProperty.isFree;
        const orderItems = {
            ...selectedGroups,
            ['selected' + topUpCategory]: selectedProperty
        };
        setSelectedGroups(orderItems);
        handleOrderTotal(orderItems);
    }

    const toggleIssueAsVoucher = (topUpCategory) => {
        const selectedProperty = selectedGroups['selected' + topUpCategory];
        selectedProperty.issueAsVoucher = !selectedProperty.issueAsVoucher;
        setSelectedGroups({
            ...selectedGroups,
            ['selected' + topUpCategory]: selectedProperty
        });
    }

    const confirmOrder = () => {
        setOrderConfirmed(!orderConfirmed);

        if (orderConfirmed) {
            setTopTitle({
                top: T[locale]['topUpSimCard'],
                sub: T[locale]['topUpSubHeading'],
            });
        } else {
            setTopTitle({
                top: T[locale]['confirmOrder'],
                sub: T[locale]['emptyString'],
            });
        }
    }

    return (
        <div className="cart col-sm-3 d-flex flex-column order-first order-sm-last">
            <dl className="order-top">
                {msisdn &&
                    <Fragment>
                        <dt>
                            {T[locale].mobileNumber}:
                        </dt>
                        <dd>
                            {msisdn}
                        </dd>
                    </Fragment>
                }
                <dt>
                    {T[locale].name}:
                </dt>
                <dd>{subscriberName}</dd>
                <dt>
                    {T[locale].lastProduct}:
                </dt>
                <dd>
                    {
                        lastProduct?.name
                            ?
                            ' '+lastProduct.name +
                            (lastProduct?.expiryDate
                                ?
                                ' (exp.: '+lastProduct.expiryDate+')'
                                :
                                ' ('+T[locale].noExpiry+')')
                            :
                            ' '+T[locale].none
                    }
                </dd>
            </dl>

            {Object.keys(selectedGroups).map((groupKey) => {

                if (selectedGroups?.[groupKey]) {
                    return (
                        <CartProduct
                            key={selectedGroups?.[groupKey]?.productId}
                            category={T[locale]?.[selectedGroups?.[groupKey]?.topupCategory]?.category}
                            product={selectedGroups?.[groupKey]}
                            onClick={(topUpCategory) => removeProduct(topUpCategory)}
                            toggleIsFree={(topUpCategory) => toggleIsFree(topUpCategory)}
                            toggleIssueAsVoucher={(topUpCategory) => toggleIssueAsVoucher(topUpCategory)}
                        />
                    );
                }
            })}

            <p className="order-total">
                {T[locale].topupTotal}: {orderTotal},-
            </p>
            <p>
                <button
                    className="btn btn-success btn-block"
                    onClick={() => confirmOrder()}
                    disabled={!orderConfirmEnabled}
                >
                    {T[locale].next}
                </button>
            </p>
        </div>
    );
}

export default Cart;
