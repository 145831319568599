import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Spinner } from './Elements';
import { Languages } from '../../resources/languages';
import { Translations } from '../../resources/translations.js';
import MCpos from '../../services/MCpos';
import { MainContext } from "../contexts/MainContext";
import useSetLang from "../hooks/useSetLang";

export const Header = ({ backLink }) => {

    const {
        locale,
        setLoggedIn,
        setLoading,
        loading
    } = useContext(MainContext);


    const logOut = (userName) => {
        setLoading(true);
        MCpos.logOut(userName)
            .then(() => {
                setLoading(false);
                setLoggedIn(false);
            })
            .catch((e) => {
                setLoading(false);
                setLoggedIn(false);
            });
        MCpos.clearLogin();
    }

    if(loading) {
        return <Spinner type="overlay" />
    }

    return (
        <nav className="navbar navbar-expand bg-primary">
            <div className="container-fluid">
                <Link className="back-navigation" to={backLink}>{Translations[locale].back}</Link>
                <div className="navbar-nav align-items-center">
                    <ToggleLanguage />
                    <UserInfo
                        onClick={() => logOut(null)}
                    >
                        (Icon)
                    </UserInfo>
                </div>
            </div>
        </nav>
    );
}

export const ToggleLanguage = () => {
    const {
        locale,
    } = useContext(MainContext);

    const setLang = useSetLang();

    const changeLanguage = (event) => {
        let changeTo = event.target.dataset.value;
        setLang(changeTo);
    }
    return (
        <div className="nav-item language-nav">
            <LangDropdown onClick={(event) => changeLanguage(event)}>
                {Translations[locale][Languages[locale].name.toLowerCase()]}
            </LangDropdown>
        </div>
    )
}

export const LangDropdown = ({ onClick, children }) => {
    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="dropdown me-3">
            <button
                className="btn btn-light dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {children}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {Object.keys(Languages).map(function (language) {
                    return (
                        <a key={language} data-value={language} onClick={onClick} className="dropdown-item" href="#">
                            {Translations[locale][Languages[language].name.toLowerCase()]}
                        </a>
                    );
                })}
            </div>
        </div>
    )
};

const UserInfo = ({ onClick, children }) => {
    const {
        locale,
        loading
    } = useContext(MainContext);
    return (
        <div className="dropdown nav-item">
            <button
                className="btn-icon btn-icon--user dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span className="visually-hidden">{children}</span>
            </button>
            <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                <p>{MCpos.user}</p>
                <button className="btn btn-primary logout-btn" onClick={onClick} disabled={loading}>
                    {Translations[locale].logout}
                </button>
            </div>
        </div>
    )
};

export default Header;
