import { useContext } from 'react';
import { Translations as T } from '../../../resources/translations';
import ProductCard from './ProductCard';
import { MainContext } from '../../contexts/MainContext';

const SelectSubscription = ({ selectedSubGroup, onClick }) => {

    const {
        locale,
    } = useContext(MainContext);

    return (
        <div id="selectsub">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            {selectedSubGroup?.products.map((product) => {
                                return (
                                    <ProductCard key={product?.productId} product={product} onClick={onClick} />
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="pt-4 mb-0">
                            <button type="button" className="btn btn-outline-primary" onClick={() => onClick()}>
                                {T[locale].back}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SelectSubscription;
