import { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import MCpos from '../services/MCpos';

import { Spinner } from '../components/layout/Elements';

import Login from '../components/pages/Login';
import LangMenu from '../components/pages/LangMenu';
import MainMenu from '../components/pages/MainMenu';
import PrePaidMenu from '../components/pages/PrePaidMenu';
import TopUpPage from '../components/pages/TopUp';
import RecentTopUps from '../components/pages/RecentTopUps';
import ReplaceSim from '../components/pages/ReplaceSim';
import StarterPack from '../components/pages/StarterPack';
import Porting from '../components/pages/Porting';
import ThankYou from '../components/pages/ThankYou';
import Location from '../components/pages/Location';
import NotFound from '../components/pages/NotFound';
import { MainContext } from "../components/contexts/MainContext";
import useSetLang from "../components/hooks/useSetLang";

function AppRouter() {

	const {
		loading,
		setLoading,
		loggedIn,
		setLoggedIn,
	} = useContext(MainContext);

	const setLang = useSetLang();

	const [isPosStand, setPosStand] = useState(false);

	const [isPosAdmin, setPosAdmin] = useState(false);

	const logOut = (userName) => {
        setLoading(true);
        MCpos.logOut(userName)
            .then(() => {
                setLoading(false);
                setLoggedIn(false);
            })
            .catch((e) => {
                setLoading(false);
                setLoggedIn(false);
            });
        MCpos.clearLogin();
    }

	const checkPriviliges = () => {
		MCpos.checkAccessToken().then((response) => {
			if (response.status !== 200) {
				setLoading(false);
				setLoggedIn(false);
			} else {
				if (MCpos.checkAccessScope(response.data.scopeValue)) {
					setLoading(false);
					setLoggedIn(true);
					MCpos.user = response.data.userId;
					setLang(MCpos.lang);

					if (response.data.scopeValue.includes('posstand')) {
						setPosStand(true);
					} else {
						setPosStand(false);
					}
					if (response.data.scopeValue.includes('posadmin')) {
						setPosAdmin(true);
					} else {
						setPosAdmin(false);
					}
				} else {
					logOut(response.data.userId);
				}
			}
		}).catch(error => {
			console.log(error);
			setLoading(false);
			setLoggedIn(false);
		})
		.finally(()=> {
		});
	}

	useEffect(() => {
		setLoading(true);
		!MCpos.local && MCpos.refreshAccessToken().then((response) => {
			if (response?.status !== 200) {
				setLoading(false);
				setLoggedIn(false);
			} else {
				MCpos.accessToken = response.data.accessToken;
				/**
				 * Need this below so our settings (for example language and native will stay)
				 * TODO: move the settings to session/local storage to separate from the accesstoken/accessTokenExpires
				 */
				MCpos.updateCookie(
					response.data.accessToken,
					response.data.accessTokenExpires,
					MCpos.lang,
					MCpos.native
				);
				checkPriviliges();
			}
		}).catch(error => {
			console.log(error);
			setLoading(false);
			setLoggedIn(false);
		})
		.finally(()=> {
		});

		// If we are local we can use this logic below
		MCpos.local && checkPriviliges();


	}, []);

	// This is our login logic in case of expired accessToken
	useEffect(() => {
			const interval = setInterval(() => {
				if (loggedIn) {
					// get and update fresh cookies
					MCpos.getCookie();
					if (MCpos.isAccessTokenExpired()) {
						logOut(null);
					}
				}
			},60*1000);
		return () => clearInterval(interval);
	}, [loggedIn]);

	if (loading) {
		return (
			<Spinner type="overlay" />
		);
	}

	if (loggedIn) {
		return (
			<Router>
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/langmenu" />} />
					<Route path="/login" render={() => <Redirect to="/langmenu" />} />
					<Route path="/location" render={() => <Location isPosStand={isPosStand} isPosAdmin={isPosAdmin} />} />
					<Route path="/langmenu" render={() => <LangMenu />} />
					<Route
						path="/menu"
						render={() => (
							<MainMenu isPosStand={isPosStand} />
						)}
					/>
					<Route
						path="/prepaidmenu"
						render={() => <PrePaidMenu />}
					/>
					{!isPosStand ? (
						<Route
							path="/topup"
							render={() => <TopUpPage />}
						/>
					) : null}
					<Route
						path="/replacementsim"
						render={() => <ReplaceSim />}
					/>
					<Route
						path="/starterpack"
						render={() => <StarterPack />}
					/>
					<Route
						path="/porting"
						render={() => <Porting />}
					/>
					{!isPosStand ? (
						<Route
							path="/recenttopups"
							render={() => <RecentTopUps />}
						/>
					) : null}
					<Route
						path="/thankyou"
						render={() => <ThankYou />}
					/>
					<Route path="*"
						   render={() => <NotFound status={404} />}
					/>
				</Switch>
			</Router>
		);
	} else {
		return (
			<Router>
				<Route
					path="/login"
					render={() => <Login setPosStand={setPosStand} setPosAdmin={setPosAdmin} />}
				/>
				<Redirect to="/login" />
			</Router>
		);
	}


}

export default AppRouter;
