import { useContext } from 'react';
import { Translations as T } from "../../../resources/translations";
import { ShadowCard } from "../../layout/Elements";
import GetMsisdn from "./GetMsisdn";
import SelectMultiStepSubscription from './SelectMultiStepSubscription';
import MyCash from './MyCash';
import MyData from './MyData';
import SelectSubscription from './SelectSubscription';
import SelectMinute from './SelectMinute';
import OrderSummary from './OrderSummary';
import Cart from './Cart';
import { TopUpContext } from "../../contexts/TopUpContext";
import { MainContext } from "../../contexts/MainContext";
import useTopUpSelectSub from "../../hooks/useTopUpSelectSub";
import useTopUpUpdateCart from "../../hooks/useTopUpUpdateCart";


const TopUp = () => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        selectedGroups,
        setSelectedGroups,
        myCash,
        myData,
        subGroups,
        iMinutesData,
        msisdnConfirmed,
        orderConfirmed,
        showSubs,
        setShowSubs,
        subGroup,
        setSubGroup,
        showMinutes,
        setShowMinutes,
        setTopTitle
    } = useContext(TopUpContext);

    const selectSub = useTopUpSelectSub();
    const [ handleOrderTotal ] = useTopUpUpdateCart();

    const handleShowSubs = (subGroup) => {

        setShowSubs(true);
        setSubGroup(subGroup);

        setTopTitle({
            top: T[locale]?.[subGroup]?.TopTitle,
            sub: T[locale]['selectAPackage'],
        });

        window.scrollTo({ top: 0 });
    }

    const handleShowMinutes = () => {
        setShowMinutes(true);

        setTopTitle({
            top: T[locale]['internationalMinutesTopTitle'],
            sub: T[locale]['choose'],
        });

        window.scrollTo({ top: 0 });
    }

    const selectMinute = (iMinute) => {
        if (iMinute) {
            iMinute.isFree= false;
            iMinute.issueAsVoucher = false;

            const orderItems = {
                ...selectedGroups,
                ['selectedPACKAGE']: null,
                selectedTOPUP: iMinute
            };
            setShowMinutes(false);
            setSelectedGroups(orderItems);
            handleOrderTotal(orderItems);
        } else {
            setShowMinutes(false);
        }

        setTopTitle({
            top: T[locale]['topUpSimCard'],
            sub: T[locale]['topUpSubHeading'],
        });

        window.scrollTo({ top: 0 });
    }

    if (!msisdnConfirmed) {
        return (
            <GetMsisdn />
        );
    }

    if (showSubs) {
        const selectedSubGroup = subGroups.filter(group=>group.name === subGroup)[0];
        const isMultiStep = selectedSubGroup.products[0].productExtras;

        if (isMultiStep) {
            return (
                <SelectMultiStepSubscription
                    group={selectedSubGroup}
                    onClick={(product) => selectSub(product)}
                />
            );
        }

        return (
            <SelectSubscription
                selectedSubGroup={selectedSubGroup}
                onClick={(product) => selectSub(product)}
            />
        );
    }

    if (showMinutes) {
        return (
            <SelectMinute
                onClick={(iMinute) => selectMinute(iMinute)}
            />
        );
    }
    if (orderConfirmed) {
        return (
            <OrderSummary />
        );
    }

    return (
        <div id="Prepaid">
            <div className="row g-4">
                <div className="col-sm-3">
                    {myCash.length > 0 && (
                        <ShadowCard>
                            <MyCash heading={T[locale].saldo} />
                        </ShadowCard>
                    )}
                </div>

                <div className="col-sm-3">
                    {myData.length > 0 && (
                        <ShadowCard>
                            <MyData heading={T[locale].data} />
                        </ShadowCard>
                    )}
                </div>

                <div className="col-sm-3">
                    {subGroups.map((group) => {
                        return (
                            <ShadowCard key={group.name}>
                                <h3>{group.name}</h3>
                                <p>{T[locale]?.[group?.name]?.Info}</p>
                                <button className="btn btn-outline-primary" onClick={() => handleShowSubs(group.name)}>
                                    {T[locale].seePackages}
                                </button>
                            </ShadowCard>
                        );
                    })}
                    {iMinutesData.length > 0 && (
                        <ShadowCard>
                            <h3>{T[locale].internationalMinutes}</h3>
                            <p>{T[locale].internationalMinutesInfo}</p>
                            <button className="btn btn-outline-primary" onClick={() => handleShowMinutes()}>
                                {T[locale].seePackages}
                            </button>
                        </ShadowCard>
                    )}
                </div>

                <Cart />

            </div>
        </div>
    );
}
export default TopUp;
