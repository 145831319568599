import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import ValidatorService from '../../../services/validation-service';
import { Translations } from '../../../resources/translations';
import MCpos from '../../../services/MCpos';
import {
    AddressRule,
    BirthDateRule, DefaultValidationOptions,
    EmailRule,
    ICCRuleStartPack,
    MSISDNRule,
    ReqFieldRule
} from '../../../resources/validation-rules';
import Joi from 'joi-browser';
import OrderOk from './OrderOk';
import SelectId from './SelectId';
import GetIdNumber from './GetIdNumber';
import { TextInput, Alert, CountryInput, ErrorInfo, Errorlist, LanguageInput, CheckBoxInput, Spinner } from '../../layout/Elements';
import DatePicker from 'react-datepicker';
import mc from '../../../resources/mycallsettings';
import { MainContext } from '../../contexts/MainContext';

const ValidateForm = () => {

    const {
        locale
    } = useContext(MainContext);

    const [errorMsg, setErrorMsg] = useState('');
    const [errorList, setErrorList] = useState(null);
    const [orderProgress, setOrderProgress] = useState(false);
    const [orderOk, setOrderOk] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [carePackageMsg, setCarePackageMsg] = useState(null);
    const [idType, setIdType] = useState(null);
    const [enableMycallCarePackages, setEnableMycallCarePackages] = useState(false);
    const [birthDate, setBirthDate] = useState(null);
    const [idConfirmed, setIdConfirmed] = useState(false);

    useEffect(() => {
        setFormValues({
            msisdn: '',
            icc: '',
            idNumber: '',
            firstName: '',
            lastName: '',
            address: '',
            email: '',
            zip: '',
            city: '',
            country: 'Norge',
            language: 'NO',
            mycallCare: false,
            mycallCareUkraine: false,
        })
    }, []);

    const handleConfirmData = () => {
        setErrorMsg('');
        setOrderProgress(true);

        let order = {
            MSISDN:     formValues.msisdn,
            ICC:        formValues.icc,
            FIRST_NAME: formValues.firstName,
            LAST_NAME:  formValues.lastName,
            BIRTHDATE:  moment.parseZone(birthDate).format('YYYY-MM-DD'),
            IDTYPE:     idType.navicode,
            IDNUMBER:   formValues.idNumber,
            ADDRESS:    formValues.address,
            ZIP:        formValues.zip,
            CITY:       formValues.city,
            COUNTRY:    formValues.country,
            EMAIL:      formValues.email,
            SOURCE:     MCpos.user,
            LANGUAGE:   formValues.language,
            LOCATION:   MCpos.location,
            CARE_PACKAGE: formValues.mycallCare ? 1 : (formValues.mycallCareUkraine ? 2 : 0)
        }

        if (validateForm(order) === false) {
            return false;
        }

        MCpos.registerStartpackage(order).then((response) => {
            if (response.data.STATUS === 404 || response.data.MSR_STATUS !== '0' || response.status === 500) {
                let errorMessage = '';
                if (response.data.description) {
                    errorMessage = response.data.description;
                }
                if (response.data.MSR_ERROR_REASON) {
                    errorMessage = response.data.MSR_ERROR_REASON;
                }
                if (response.data.message) {
                    errorMessage = response.data.message;
                }
                setErrorMsg(errorMessage);
                setOrderProgress(false);
            } else {
                setOrderOk(true);
                const carePackageMsg = addCarePackageMsg(response);
                setCarePackageMsg(carePackageMsg);
            }
        }).catch((error) => {
            setErrorMsg(`Server error ${error}`);
            setOrderProgress(false);
        });
    }

    const handleInput = ({target: {name, value, checked, type}}) => {
        setFormValues({...formValues, [name]: type === 'checkbox' ? checked : value});
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (event.target.name === 'idNumber') {
                confirmId();
            }
        }
    }

    const handleBirthChange = (date) => {
        setErrorMsg('');
        setBirthDate(date)
        if (!ValidatorService.validateAge(date)) {
            setErrorMsg(Translations[locale].custUnderAge)
        }
    }

    const getIdType = (idType, mycallCarePackages) => {
        setIdType(idType);
        setEnableMycallCarePackages(mycallCarePackages);
    }

    const confirmId = () => {
        if (formValues.idNumber) {
            setIdConfirmed(true);
        }
    }

    const addCarePackageMsg = (response) => {
        // we add extra message based on CARE_PACKAGE_RESULT
        if (formValues.mycallCare || formValues.mycallCareUkraine) {
            if (response.data.CARE_PACKAGE_RESULT === 'Package allocated') {
                return Translations[locale].packageApproved;
            } else if(response.data.CARE_PACKAGE_RESULT === 'Package not approved') {
                return Translations[locale].packageNotApproved;
            }
        }
        return null;
    }

    const validateForm = (order) => {
        setErrorList(null);
        const schema = {
            MSISDN: MSISDNRule(locale),
            ICC: ICCRuleStartPack(locale),
            FIRST_NAME: ReqFieldRule(Translations[locale].firstName, locale),
            LAST_NAME: ReqFieldRule(Translations[locale].lastName, locale),
            ZIP: ReqFieldRule(Translations[locale].zip, locale),
            CITY: ReqFieldRule(Translations[locale].city, locale),
            BIRTHDATE: BirthDateRule(Translations[locale].birthdate, locale),
            EMAIL: EmailRule(Translations[locale].email, locale),
            ADDRESS: AddressRule(Translations[locale].address, locale),
            COUNTRY: ReqFieldRule(Translations[locale].country, locale),
        }
        let result = Joi.validate(order, schema, DefaultValidationOptions);
        if (result.error !== null) {
            let errors = ValidatorService.parseErrors(result.error.details);
            setErrorList(errors);
            setOrderProgress(false);
            return false;
        }
        return true;
    }

    if (orderOk) {
        return <OrderOk carePackageMsg={carePackageMsg} />
    }
    if (!idType) {
        return <SelectId onClick={(idType, mycallCarePackages) => getIdType(idType, mycallCarePackages)} />
    } else if (idType !== '' && idConfirmed === false) {
        return <GetIdNumber
            value={formValues.idNumber}
            onClick={() => confirmId()}
            onChange={(event) => handleInput(event)}
            onKeyPress={(event) => handleKeyPress(event)}
            idNumber={formValues.idNumber}
        />
    }

    if (orderProgress) {
        return (
            <Spinner type="overlay" />
        )
    }

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div id="verifyform">
                    <h1 className="title">{Translations[locale].startPackage}</h1>

                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput type="tel" autoFocus="yes" name="msisdn" tabIndex="1" value={formValues.msisdn} required="yes" label={Translations[locale].newMycallNumber} onChange={(event) => handleInput(event)} />
                        </div>
                        <div className="col-sm-6">
                            <TextInput type="tel" name="icc" tabIndex="1" value={formValues.icc} label={Translations[locale].newSimCardNumber + ' (' + Translations[locale].lastDigits + ')'} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput name="idType" readOnly="yes" tabIndex="2" value={Translations[locale][idType.transCode]} label={Translations[locale].idType} />
                        </div>
                        <div className="col-sm-6">
                            <TextInput name="firstName" tabIndex="8" value={formValues.firstName} required="yes" label={Translations[locale].firstName} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label">{Translations[locale].birthdate} *</label>
                                <DatePicker
                                    className="form-control"
                                    selected={birthDate}
                                    onChange={(date) => handleBirthChange(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <TextInput name="lastName" tabIndex="9" value={formValues.lastName} required="yes" label={Translations[locale].lastName} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <TextInput name="address" tabIndex="4" value={formValues.address} label={Translations[locale].address} onChange={(event) => handleInput(event)} />
                        </div>
                        <div className="col-sm-6">
                            <TextInput name="email" tabIndex="10" value={formValues.email} label={Translations[locale].email} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3">
                            <TextInput name="zip" tabIndex="5" value={formValues.zip} required="yes" label={Translations[locale].zip} onChange={(event) => handleInput(event)} />
                        </div>
                        <div className="col-sm-3">
                            <TextInput name="city" tabIndex="6" value={formValues.city} required="yes" label={Translations[locale].city} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <CountryInput name="country" tabIndex="7" value={formValues.country} label={Translations[locale].country} onChange={(event) => handleInput(event)} />
                        </div>
                        <div className="col-sm-6">
                            <LanguageInput name="language" tabIndex="8" value={formValues.language} label={Translations[locale].language} onChange={(event) => handleInput(event)} />
                        </div>
                    </div>

                    {enableMycallCarePackages &&
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-inline-block me-4">
                                    <CheckBoxInput inputClassName={'me-2'} name="mycallCare" tabIndex="9" value={formValues.mycallCare} isChecked={formValues.mycallCare} label={Translations[locale].mycallCare} onChange={(event) => handleInput(event)} />
                                </div>
                                <div className="d-inline-block">
                                    <CheckBoxInput inputClassName={'me-2'} name="mycallCareUkraine" tabIndex="10" value={formValues.mycallCareUkraine} isChecked={formValues.mycallCareUkraine} label={Translations[locale].mycallCare+' ('+Translations[locale].ukraine+')'} onChange={(event) => handleInput(event)} />
                                </div>
                            </div>
                        </div>
                    }

                    <p className="pt-4">
                        <button className="btn btn-success w-100 w-md-auto" onClick={() => handleConfirmData()} disabled={orderProgress} tabIndex="11">{Translations[locale].confirm}</button>
                    </p>
                    <Alert visible={!!errorMsg}>
                        {errorMsg}
                    </Alert>

                    {errorMsg === mc.genericDBerror && <ErrorInfo />}

                    <Errorlist heading={Translations[locale].validationError} list={errorList} item="message" />
                </div>
            </div>
        </div>
    )
}
export default ValidateForm;
