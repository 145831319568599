import { useContext } from 'react';
import { ThankYou } from '../../layout/Elements';
import { Translations } from '../../../resources/translations';
import { MainContext } from '../../contexts/MainContext';

const OrderOk = () => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <ThankYou
            title={Translations[locale].beautifulDay}
            summary={Translations[locale].thankYou}
            buttonLink="/menu"
            buttonText={Translations[locale].nextCustomer}
        />
    )
};

export default OrderOk;
