import { useContext } from 'react';
import Header from '../layout/Header';
import { MenuItem } from '../layout/Elements';
import { Translations } from '../../resources/translations.js';
import { MainContext } from "../contexts/MainContext";

const PrePaidMenu = () => {
	const {
		locale,
	} = useContext(MainContext);

	return (
		<div className="page" id="PrepaidMenu">
			<Header backLink="menu" />

			<div className="main-content">
				<div className="container-fluid">
					<h1 className="title">{Translations[locale].prepaid}</h1>
					<div className="row justify-content-center">
						<MenuItem link="/starterpack">{Translations[locale].startPackage}</MenuItem>
						<MenuItem link="/replacementsim">{Translations[locale].replacementSimCard}</MenuItem>
						<MenuItem link="/porting">{Translations[locale].transferNumber}</MenuItem>
					</div>
				</div>

			</div>
		</div>
	)
};

export default PrePaidMenu;
