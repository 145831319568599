import { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Translations as T } from '../../../resources/translations.js';
import MCpos from '../../../services/MCpos';

import { ShadowCard, Alert, Info, } from '../../layout/Elements';
import CartProduct from './CartProduct';
import { TopUpContext } from "../../contexts/TopUpContext";
import { MainContext } from "../../contexts/MainContext";

const OrderSummary = () => {

    const {
        locale,
    } = useContext(MainContext);

    const {
        msisdn,
        setTopTitle,
        orderTotal,
        errorMsg,
        setErrorMsg,
        orderProgress,
        setOrderProgress,
        orderResponse,
        setOrderResponse,
        orderOk,
        setOrderOk,
        orderConfirmed,
        selectedGroups,
        setOrderConfirmed
    } = useContext(TopUpContext);

    const orderResult = MCpos.returnOrderResult(orderResponse);

    const confirmOrder = () => {
        setOrderConfirmed(!orderConfirmed);

        if (orderConfirmed) {
            setTopTitle({
                top: T[locale]['topUpSimCard'],
                sub: T[locale]['topUpSubHeading'],
            });
        } else {
            setTopTitle({
                top: T[locale]['confirmOrder'],
                sub: T[locale]['emptyString'],
            });
        }
    }

    const executeTopUpOrder = async () => {
        setErrorMsg('');
        setOrderProgress(true);

        let order = {
            data: MCpos.prepareForDb(selectedGroups.selectedDATA),
            saldo: MCpos.prepareForDb(selectedGroups.selectedSALDO),
            sub: MCpos.prepareForDb(selectedGroups.selectedPACKAGE),
            extraMinutes: MCpos.prepareForDb(selectedGroups.selectedTOPUP),

            msisdn: msisdn,
            source: MCpos.user,
            location: MCpos.location,
        };

        MCpos.topupOrder(order)
            .then((response) => {
                if (response.status === 500) {
                    setErrorMsg(response.data.message);
                    setOrderProgress(false);
                } else {
                    setOrderResponse(response.data);
                    setOrderOk(true);
                    setOrderProgress(false);
                }
            })
            .catch((error) => {
                setErrorMsg('Server error: ' + error);
                setOrderOk(false);
                setOrderProgress(false);
            });
    }

    return (
        <div id="OrderSummary">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <ShadowCard>
                            <h3>{T[locale].yourSummary}</h3>
                            <h6>
                                {T[locale].mobileNumber}: {msisdn}
                            </h6>
                            {Object.keys(selectedGroups).map((groupKey) => {
                                if (selectedGroups?.[groupKey]) {
                                    return (
                                        <CartProduct
                                            key={selectedGroups?.[groupKey]?.productId}
                                            orderConfirmed={orderConfirmed}
                                            category={T[locale]?.[selectedGroups?.[groupKey]?.topupCategory]?.category}
                                            product={selectedGroups?.[groupKey]}
                                        />
                                    );
                                }
                            })}
                            {orderResult?.resultText && <Info statusCode={orderResult?.statusCode}>{`Status: ${orderResult?.resultText}`}</Info>}
                            <p>
                                {T[locale].topupTotal}: {orderTotal},-
                            </p>
                            {!orderOk && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="mb-0">
                                            <button type="button" className="btn btn-default" onClick={() => confirmOrder()}>
                                                {T[locale].back}
                                            </button>
                                        </p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <p className="mb-0">
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => executeTopUpOrder()}
                                                disabled={orderProgress}
                                            >
                                                {T[locale].confirm}
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            )}
                            <Alert visible={!!errorMsg}>{errorMsg}</Alert>
                        </ShadowCard>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        {orderOk && (
                            <Link to="thankyou" className="btn btn-success btn-lg">
                                {orderTotal === 0 ? T[locale].done : T[locale].paid}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OrderSummary;
