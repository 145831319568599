const endPointsV2 = (msisdn = '') => {
    return {
        getTopupProducts: '/api/products/topups/'+msisdn,
        getCountries: '/api/countries/resources',
        getTopupCountries: '/api/products/topups/countries',
        getTopupGroup: '/api/products/topups/groups/',
        getRecentTopUps: '/api/mcpos/recentTopups',
        getCurrentProducts: '/api/mcpos/users/'+msisdn+'/products/current',
        getTopupGroups: '/api/products/topups/groups/'+msisdn,
    }
};

export default endPointsV2;
