import { useContext } from 'react';
import { Translations } from '../../../resources/translations';
import { IdTypes } from '../../../resources/idtypes';
import { MainContext } from '../../contexts/MainContext';

const SelectId = ({ onClick }) => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div id="Selectid">
                    <h1 className="title">{Translations[locale].startPackage}</h1>
                    <h2 className="text-center mb-5">{Translations[locale].selectIdtype}:</h2>
                    <div className="row justify-content-center">
                        {Object.keys(IdTypes).map(function(key) {
                            return (
                                <div key={key} className="col-sm-6">
                                    <p className="mb-0">
                                        <button type="button" className="btn btn-primary btn-lg w-100 mb-5" onClick={() => onClick(IdTypes[key], IdTypes[key].mycallCarePackages)}>
                                            {Translations[locale][IdTypes[key].transCode]}
                                        </button>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SelectId;
