import { createContext, useState } from "react";

const MainContext = createContext(null);

const MainContextProvider = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const [locale, setLocale] = useState('en');
    const [loggedIn, setLoggedIn] = useState(true);

    return (
        <MainContext.Provider
            value={{
                loading,
                setLoading,
                locale,
                setLocale,
                loggedIn,
                setLoggedIn,
            }}
        >
            {children}
        </MainContext.Provider>
    )

}

export { MainContext, MainContextProvider };
