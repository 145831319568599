import { Fragment, useContext, useEffect, useState } from 'react';
import Header from '../layout/Header';
import { Info, Spinner } from '../layout/Elements';
import { Translations } from '../../resources/translations.js';
import moment from 'moment';
import { MainContext } from '../contexts/MainContext';
import MCpos from '../../services/MCpos'

const RecentTopUps = () => {
	const {
		locale,
	} = useContext(MainContext);

	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [recentTopUps, setRecentTopUps] = useState([]);

	useEffect(() => {
		setLoading(true);
		MCpos.getRecentTopUps()
			.then((response) => {
				if (
					response.data.STATUS === 404 ||
					response.status === 500 ||
					response.status === 403 ||
					response.status === '-1'
				) {
					let error = response.data.description ? response.data.description : response.data.message;
					setErrorMsg(error)
				} else {
					setRecentTopUps(response.data);
				}
				setLoading(false);
			}).catch((error) => {
			setErrorMsg(`Server error: ${error}`);
			setLoading(false);
		});
	}, []);

	return (
		<Fragment>
			{errorMsg && <Info>{errorMsg}</Info>}
			{loading && <Spinner type="overlay" />}

				<div className="page" id="recenttopups">
					<Header backLink="menu" />
					<div className="main-content">
						<div className="container-fluid">
							<h1 className="title">{Translations[locale].recentTopUps}</h1>
							{recentTopUps.map((topup, index) => {
								return (
									<article key={index} className="mb-5">
										<h3 className="text-center">{moment(topup.created).format('DD.MM.YYYY, HH:mm')}</h3>

										<div className="card">
											<div className="card-body px-5">
												<dl className="horizontal-dl">
													<dt>{Translations[locale].name}:</dt>
													<dd>{topup.fullName}</dd>

													<dt>Msisdn:</dt>
													<dd>{topup.msisdn}</dd>

													<dt>{Translations[locale].product}:</dt>
													<dd>{topup.topupName}</dd>

													<dt>{Translations[locale].price}:</dt>
													<dd>{topup.amount ?? 0},-</dd>

													{/**
													 * STATUS:
													 * 13-> in progress
													 * 5,60->failed
													 * 0->success
													 */}

													<dt>{Translations[locale].status}:</dt>
													<dd>{topup.status !== 0 ? (topup.status === 13 ? Translations[locale].statusInProgress : Translations[locale].statusFailed) : Translations[locale].statusSuccess}</dd>

													<dt>{Translations[locale].isVoucher}?:</dt>
													<dd>{topup.isVoucher ? Translations[locale].yes : Translations[locale].no}</dd>

													<dt>{Translations[locale].isFreeProduct}?:</dt>
													<dd>{topup.isFreeProduct ? Translations[locale].yes : Translations[locale].no}</dd>

													<dt>{Translations[locale].responsiblePosUser}:</dt>
													<dd>{topup.posUser}</dd>
												</dl>
											</div>
										</div>
									</article>
								)
							})}
						</div>
					</div>
				</div>
		</Fragment>
	);
}

export default RecentTopUps;
