import { Fragment } from 'react';
import { fetch } from 'whatwg-fetch'
import moment from 'moment';

import config from '../resources/config.js';
import endPoints from '../resources/endPoints.js';
import endPointsV2 from '../resources/endPointsV2.js';
import { Translations as T } from '../resources/translations.js';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

export class MCpos {
    constructor() {
        this.local = config.local ? true : false;
        this.dev = config.dev ? true : false;
        this.lang = 'en';
        this.native = false;
        this.user = '';
        this.accessToken = null;
        this.products = {
            myData: [],
            myCash: [],
            euPlus: [],
            norge: [],
            ukraine: [],
        };
        this.subGroups = [];
        this.cookieData = {
            userName: '',
            accessToken: '',
            accessExpires: '',
            lang: '',
            native: false,
        };
        (this.location = ''), (this.topupCountryData = []);
    }

    setLocation(location) {
        this.location = location;
    }

    checkAccessScope(scope) {
        return scope.search(config.scopeName) !== -1;
    }

    filterCountries(topupCountries, products, lang) {
        let newTopCountries = [];
        let includedCountries = [];

        products.forEach((product) => {
            product?.productExtras?.[0]?.productAddons.forEach((addon) => {
                includedCountries.push(addon.countryCode);
            });
        });

        for (var i = topupCountries.length - 1; i >= 0; --i) {
            if (topupCountries[i].countryCode !== 'EU' && includes(includedCountries, topupCountries[i].countryCode)) {
                newTopCountries.push(topupCountries[i]);
            }
        }

        return newTopCountries.sort((a, b) =>
            a.countryName
                .filter((obj) => obj.lang === lang.toUpperCase())[0]
                .text.localeCompare(b.countryName.filter((obj) => obj.lang === lang.toUpperCase())[0].text)
        );
    }

    getCountryData(multiSubData, selectedCountry) {
        const countryPackages = [];

        /**
         * IMPORTANT: avoid mutation !
         * need to use map function, slice for example only creates shallow copy...
         * BUT: better to avoid situations like this...
         */

        //shallow copy: const multiSubDataCopy = multiSubData.map(a => Object.assign({}, a));
        //deep copy: const multiSubDataCopy = JSON.parse(JSON.stringify(multiSubData))
        const multiSubDataCopy = JSON.parse(JSON.stringify(multiSubData));
        multiSubDataCopy.forEach((singleTopup) => {
        
            singleTopup?.productExtras?.[0]?.productAddons.forEach((singleProduct) => {
                if (singleProduct.countryCode === selectedCountry) {
                    singleTopup.productExtras[0].productAddons = [];
                    singleTopup.productExtras[0].productAddons.push(singleProduct);
                    
                    singleTopup.countryCode = selectedCountry;

                }
            });
            countryPackages.push(singleTopup);
        });

        return countryPackages;
    }

    getCountryName(topupCountries, countryCode, lang) {
        return topupCountries
        .filter((obj) => obj?.countryCode === countryCode)?.[0]
        ?.countryName.filter((obj) => obj.lang === lang?.toUpperCase())?.[0]?.text;
    }

    mapCountryName(topupCountries, iMinutesData, lang) {
        iMinutesData.forEach((product, index) => {
            iMinutesData[index].countryName = this.getCountryName(topupCountries, product?.countryCode, lang);
        });
        return iMinutesData;
    }

    fixProductDesc(product, lang) {
        let outputDesc;

        switch (product.topupCategory) {
            case 'TOPUP':
                outputDesc =
                    (product.countryCode === 'EU' ? this.getCounterAmount(product, 'MOROEU') : this.getCounterAmount(product, 'MORO')) +
                    ' ' +
                    T[lang].minutesTo +
                    ' ' +
                    product.countryName +
                    this.getDurationString(product, lang);

                break;
            case 'PACKAGE':
                outputDesc = (
                    <Fragment>
                        {product.productDescNopp}
                        {product?.productExtras?.map((extras) => {
                            const productAddon = extras?.productAddons?.[0];
                                if(includes(product.additionalProductsArray, productAddon.productId)) {
                                    return(
                                        <Fragment key={productAddon.productId}>
                                            <span><br />{' - '+productAddon?.productDesc}</span>
                                        </Fragment>
                                    );
                                }
                        })
                        }
                    </Fragment>
                );
                break;
            default:
                outputDesc = product.productDescNopp;
        }

        return outputDesc;
    }

    fixTopupCountries(topupCountryData, allCountries) {
        let includedCountries = [];
        for (let key in allCountries) {
            if (topupCountryData && topupCountryData.includes(allCountries[key].countryCode)) {
                includedCountries.push(allCountries[key]);
            }
        }

        this.topupCountryData = includedCountries;
    }

    prepareForDb(product) {

        if (product) {
            product.IS_FREE = product.isFree;
            product.ISSUE_AS_VOUCHER = product.issueAsVoucher;
            product.PRODUCT_OPTION_ID = product.productOptionId;
            product.PRODUCT_ID = product.productId;
            product.ADDITIONAL_PRODUCTS = product.additionalProducts;
        }

        return product;
    }

    getCounterAmount(product, counterType) {
        return product?.productComponents?.filter((component) => component?.counterType === counterType)?.[0]?.counterAmount;
    }

    getDurationString(product, lang) {
        const productDuration = product?.productComponents?.filter((component) => component?.counterType === 'DURATION')?.[0];
        let durationString = '';
        if( product.countryCode !== 'EU' ) {
            durationString = ( productDuration?.unitName === 'HOUR' && productDuration?.counterAmount !== 720 ) ?
                ' (' + ( parseInt( productDuration?.counterAmount ) / 24 ).toString() + ' ' + T[lang].days + ')' : '';
        }
        return durationString;
    }

    getSelectedDataAndMinutes(product) {
        const gprsAmount = this.getCounterAmount(product, 'GPRS');
        const moroAmount = this.getCounterAmount(product.productExtras?.[0]?.productAddons?.[0], 'MORO');

        return (gprsAmount >= 1000 ? gprsAmount/1000+' GB' : gprsAmount+' MB') + 
                (moroAmount 
                ?
                ' / ' + moroAmount + ' ' + T[this.lang].minutes
                :
                '');
    }

    returnOrderResult(response) {
        let statusCode = null;
        let resultText = null;

        Object.entries(response).forEach(([key, value]) => {
            if(value.status !== 0) {
                statusCode = value.status;
            }
            resultText = value.description;
        });

        return {
            statusCode: statusCode,
            resultText: resultText,
        }
    }

    fixProducts(topupData, availableGroups) {

        let saldo = [];
        let data = [];
        let subs = [];
        let iMinutesData = [];
        topupData.map((groups) => {
            groups.products.map((product) => {
                if (product.topupIsVisible === 1) {
                    if(product.topupCategory === 'TOPUP' && product.countryCode) {
                        iMinutesData.push(product);
                    }
                    else if (product.topupCategory === 'SALDO') {
                        saldo.push(product);
                    }
                    else if (product.topupCategory === 'DATA') {
                        data.push(product);
                    }
                    else if (product.topupCategory === 'PACKAGE') {
                        subs.push(product);
                    }
                }
            });
		});

        // Sort saldo
        saldo.sort(function (a, b) {
            return a.topupOrder - b.topupOrder || a.userPrice - b.userPrice;
        });

        this.subGroups = [];
        // Sort and add to subgroups
        sortBy(availableGroups, 'topupGroupRank').forEach((group) => {
            const groupProducts = subs.filter((obj) => obj.productGroup === group.topupGroupName);
            if (!isEmpty(groupProducts)) {
                this.subGroups.push({
                    name: group.topupGroupName,
                    products: groupProducts
                });
            }
        });

        this.products.myCash = saldo;
        this.products.myData = data;
        this.products.iMinutesData = iMinutesData;
    }

    login(username, password) {
        const data = Object.fromEntries(Object.entries({
            grantType: 'password',
            username: username,
            password: password,
            clientId: config.clientId,
        }).filter(([key, value]) => value !== ""));

        return this.callApi(config.apiUrl + endPoints.accessTokens, data, 'POST', { credentials: 'include' });
    }

    logOut(userName = null) {
        const data = {clientId: config.clientId, userId: this.user || userName || ''};
        return this.callApi(config.apiUrl + endPoints.logOut, data, 'POST', { credentials: 'include' });
    }

    refreshAccessToken() {
        const data = Object.fromEntries(Object.entries({
            grantType: 'refreshToken',
            clientId: config.clientId,
        }).filter(([key, value]) => value !== ""));
        return this.callApi(config.apiUrl + endPoints.accessTokens, data, 'POST', { credentials: 'include' });
    }

    checkAccessToken() {
        this.getCookie();
        if (this.cookieData.accessToken) {
            this.accessToken = this.cookieData.accessToken;
        }
        if (this.cookieData.accessExpires) {
            this.lang = this.cookieData.lang;
            this.native = this.cookieData.native;
        }
        const data = { accessToken: this.accessToken };
        return this.callApi(config.apiUrl + endPoints.checkAccessToken, data, 'POST');
    }

    getTopupGroups(msisdn) {
        const endpoint = endPointsV2(msisdn).getTopupGroups;
        return this.callApiV2(config.apiUrl + endpoint,
            null,
            'GET',
            msisdn
        );
    }

    getCountries() {
        let endpoint = endPointsV2().getCountries;
        return this.callApiV2(config.apiUrl + endpoint, null, 'GET');
    }

    getTopupCountries(msisdn) {
        let endpoint = endPointsV2(msisdn).getTopupCountries;
        return this.callApiV2(config.apiUrl + endpoint, null, 'GET', msisdn);
    }

    getTopupProducts(msisdn) {
        let endpoint = endPointsV2(msisdn).getTopupProducts;
        return this.callApiV2(config.apiUrl + endpoint, null, 'GET', msisdn);
    }

    getCurrentProducts(msisdn) {
        let endpoint = endPointsV2(msisdn).getCurrentProducts;
        return this.callApiV2(config.apiUrl + endpoint, null, 'GET', msisdn);
    }

    getRecentTopUps() {
        let endpoint = endPointsV2().getRecentTopUps;
        return this.callApiV2(config.apiUrl + endpoint, null, 'GET');
    }

    getUserInfo(msisdn) {
        let endpoint = endPoints.getUserInfo + '?username=' + msisdn;
        return this.callApi(config.apiUrl + endpoint, null, 'GET');
    }

    validatePrepaidNumber(msisdn) {
        let endpoint = endPoints.validatePrepaidNumber + '?username=' + msisdn;
        return this.callApi(config.apiUrl + endpoint, null, 'GET');
    }

    activateNewSim(msisdn, simNumber) {
        let data = { username: msisdn, activationCode: simNumber };
        return this.callApi(config.apiUrl + endPoints.activateNewSim, data, 'POST');
    }

    purchaseOrder(order) {
        return this.callApi(config.apiUrl + endPoints.purchaseOrder, order, 'POST');
    }

    registerStartpackage(order) {
        return this.callApi(config.apiUrl + endPoints.registerStartpackage, order, 'POST');
    }

    topupOrder(order) {
        return this.callApi(config.apiUrl + endPoints.topupOrder, order, 'POST');
    }

    getSubscriptionInfo(langid = 2) {
        return this.callApi(
            config.apiUrl + endPoints.getSubscriptionInfo + '?pageName=mcmobileapp&languageId=' + langid,
            null,
            'GET'
        );
    }

    callApi(url, data, method = 'POST', extraConfigs = {}) {
        let init = {
            method: method,
            ...extraConfigs,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.accessToken,
                'X-Channel': 'POS',
                'X-Source': 'WEB',
                'X-DataVersion': 7,
                'X-Platform': 'WEB',
                'X-AppVersion': 'ANY/2.0.0',
            },
        };
        data ? (init['body'] = JSON.stringify(data)) : null;
        return fetch(url, init)
            .then(async (response) => {
                return { data: await response.json(), status: response.status }
            })
            .then(data => {
                return data;
            });
    }

    callApiV2(url, data, method = 'POST', msisdn, headers = {}, extraConfigs = {}) {
        let init = {
            method: method,
            ...extraConfigs,
            headers: {
                Accept: 'application/x.mc-app.v2+json',
                Authorization: 'Bearer ' + this.accessToken,
                'Content-Type': 'application/json',
                'X-Identity': '',
                'X-Username': this.user,
                'X-Msisdn': msisdn,
                'X-Channel': 'POS',
                'X-Source': 'WEB',
                'X-DataVersion': 7,
                'X-Platform': 'WEB',
                'X-AppVersion': 'ANY/2.0.0',
                ...headers,
            },
        };
        data ? (init['body'] = JSON.stringify(data)) : null;
        return fetch(url, init)
            .then(async (response) => {
                return { data: await response.json(), status: response.status }
            })
            .then(data => {
                return data;
            });
    }

    updateCookie(accessToken, accessTokenExpires, lang, native) {
        this.cookieData.accessToken = accessToken;
        this.cookieData.accessExpires = moment(accessTokenExpires).toDate().toUTCString();
        this.cookieData.lang = lang;
        this.cookieData.native = native;
        this.setCookie();
    }

    getCookie() {
        if (this.getCookieValue(config.cookieName)) {
            this.cookieData = JSON.parse(this.getCookieValue(config.cookieName));
            return this.cookieData;
        }
        return false;
    }

    isAccessTokenExpired() {
        return moment(new Date()).isAfter(moment(this.cookieData.accessExpires));
    }

    setCookie() {
        const momentDateObject = moment(this.cookieData.accessExpires);
        const dateObject = momentDateObject.toDate();
        document.cookie = `${config.cookieName}=${JSON.stringify(this.cookieData)}; expires=${dateObject.toUTCString()}; path=/`;
    }

    getCookieValue(name) {
        let re = new RegExp(name + '=([^;]+)');
        let value = re.exec(document.cookie);
        return value != null ? unescape(value[1]) : null;
    }

    clearLogin() {
        this.accessToken = null;
        this.user = '';
        this.lang = 'en';
        this.native = false;
        document.cookie = config.cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export default new MCpos();
