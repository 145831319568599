import Header from '../../layout/Header';
import ValidateForm from './ValidateForm';

const StarterPack = () => (
    <div className="page" id="Verifyinformation">
        <Header backLink="menu" />
        <ValidateForm />
    </div>
);

export default StarterPack;
