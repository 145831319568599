import { useContext } from 'react';
import { TopUpContext } from "../contexts/TopUpContext";
import { Translations as T } from "../../resources/translations";
import useTopUpUpdateCart from "./useTopUpUpdateCart";
import { MainContext } from "../contexts/MainContext";

function useTopUpSelectSub() {
    const {
        locale,
    } = useContext(MainContext);

    const {
        selectedGroups,
        setShowSubs,
        setSelectedGroups,
        setTopTitle
    } = useContext(TopUpContext);

    const [ handleOrderTotal ] = useTopUpUpdateCart();

    return (product) => {
        if (product) {
            product.isFree = false;
            product.issueAsVoucher = false;

            const orderItems = {
                ...selectedGroups,
                ['selectedTOPUP']: null,
                selectedPACKAGE: product
            };
            setShowSubs(false);
            setSelectedGroups(orderItems);
            handleOrderTotal(orderItems);
        } else {
            setShowSubs(false);
        }

        setTopTitle({
            top: T[locale]['topUpSimCard'],
            sub: T[locale]['topUpSubHeading'],
        });

        window.scrollTo({top: 0});
    };
}

export default useTopUpSelectSub;
