import { useContext } from 'react';
import { Translations } from '../../../resources/translations';
import { TextInput } from '../../layout/Elements';
import { MainContext } from '../../contexts/MainContext';

const GetIdNumber = ({idNumber, onChange, onClick, onKeyPress}) => {

    const {
        locale
    } = useContext(MainContext);

    return (
        <div className="main-content">
            <div className="container-fluid">
                <div id="GetIdNumber">
                    <h1 className="title">{Translations[locale].startPackage}</h1>
                    <h2 className="text-center">{Translations[locale].idNumber}</h2>
                    <div className="row justify-content-center align-items-end">
                        <div className="col-sm-6">
                            <TextInput
                                autoFocus="yes"
                                type="text"
                                name="idNumber"
                                label={Translations[locale].idNumber}
                                value={idNumber}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                                required="yes"
                                maxLength="20"
                            />
                        </div>
                        <div className="col-sm-auto">
                            <button className="btn btn-success mb-3 w-100" onClick={onClick}>
                                {Translations[locale].confirm}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GetIdNumber;
