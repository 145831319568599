import { useContext, Fragment } from 'react';
import { ProductSelector } from "../../layout/Elements";
import { TopUpContext } from "../../contexts/TopUpContext";
import useTopUpUpdateCart from "../../hooks/useTopUpUpdateCart";

const MyData = ({ heading }) => {

    const {
        selectedGroups,
        setSelectedGroups,
        myData,
    } = useContext(TopUpContext);

    const [ handleOrderTotal ] = useTopUpUpdateCart();

    const selectData = (data) => {
        data.isFree = false;
        data.issueAsVoucher = false;

        const orderItems = {
            ...selectedGroups,
            selectedDATA: data
        };
        setSelectedGroups(orderItems);
        handleOrderTotal(orderItems);
    }

    return (
        <Fragment>
            <h3>{heading}</h3>

            {Object.keys(myData).map(function (key) {
                return (
                    <ProductSelector
                        key={key}
                        data={myData[key].productDescNopp}
                        onClick={() => selectData(myData[key])}
                    >
                        <strong>{myData[key].productDescNopp}</strong>
                        <br />
                        {myData[key].userPrice},-
                    </ProductSelector>
                );
            })}
        </Fragment>
    )

};

export default MyData;
